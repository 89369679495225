import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Geosuggest from './Geosuggest';
import AddressConfirmationModal from './AddressConfirmationModal';
import './Geosuggest.css';
import './AuthStyles.css';
import imageCompression from 'browser-image-compression';
import { Box, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import './Instrution.css';

function VendorProfileForm() {
    const [vendorData, setVendorData] = useState({
        name: '',
        image: null,
        additionalImages: [],
        settings: [],
        stageTypes: [],
        capacity: '50',
        full_address: '',  // Changed from 'location' to 'full_address'
        latitude: null,    // Added latitude
        longitude: null,   // Added longitude
        bio: '',
        amenities: '',
        phoneNumber: '',
        preferredContactMethod: ''

    });
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isStageDropdownOpen, setIsStageDropdownOpen] = useState(false); 
    const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [profileImagePreview, setProfileImagePreview] = useState(null); // Preview for profile image
    const [additionalImagePreviews, setAdditionalImagePreviews] = useState([]);
    const stageDropdownRef = useRef(null);
    const navigate = useNavigate();

    const handleAddressConfirmation = () => {
        setIsAddressModalOpen(true);
    };

    const handleConfirmAddress = (address, coordinates) => {
        setVendorData({
            ...vendorData,
            full_address: address,
            latitude: coordinates.lat,
            longitude: coordinates.lng
        });
    };

    const instructions = {
        name: "Enter the name of your bar or venue as you would like it to appear on your profile. This is how artists and other users will identify your establishment.",
        preferredContactMethod: "Select whether you'd like to provide a phone number, cell number, or both. This information will be used for transaction receipts and may also be used for communication with artists regarding bookings.",
        location: "Please search for your location and use the item below to confirm your address and add your venue to the map. This ensures local artists can find and connect with your venue for potential bookings.",
        image: "Upload clear, high-quality images of the outside and inside of your venue (2-3 images). This will help artists visualize the setting and atmosphere of your bar.",
        settings: "Describe your venue's setting (indoor, outdoor, or a combination of both). This helps artists understand the environment they'll be performing in. Check all that apply.",
        stageTypes: "Select the option that best describes your stage setup. If your venue offers multiple stage sizes or configurations, feel free to select all that apply.",
        capacity: "Please use the sliding bar to select a value that reflects your venue's capacity. This will help artists understand the size of your audience and better gauge whether your venue is a good fit for their performance.",
        bio: "Tell us more about your venue's atmosphere and what makes it special. Whether it's your music preferences, ambiance, or any unique features, this is your chance to stand out to artists. Your bio can be as short or long as you like.",
        other_notes: "Include any additional notes or details about your venue that might be important for artists, such as sound equipment availability, green room access, or any special requirements."
    };

    const settingOptions = ['Bar', 'Nightclub', 'Café', 'Restaurant', 'Lounge', 'Concert Hall','Outdoor Space', 'Brewery/Tavern', 'Private Event Space', 'Other' ];

    const stageOptions = [
        'Small (Suitable for 1-2 performers)',
        'Medium (Suitable for 3-4 performers)',
        'Large (Suitable for 4+ performers)',
        'Adjustable/Movable Stage',
        'No Dedicated Stage (floor space only)',
        'Outdoor Stage Area'
    ];

    const handleSelect = (suggestion) => {
        setVendorData({ 
            ...vendorData, 
            full_address: suggestion.label,
            latitude: suggestion.location.lat,
            longitude: suggestion.location.lng
        });
    };
    const handleChange = (event) => {
        const { name, type, value, files, checked } = event.target;
    
        if (type === 'file') {
            if (name === 'image') {
                const file = files[0];
                setVendorData({ ...vendorData, [name]: file });
    
                // Generate preview for profile image
                if (file) {
                    setProfileImagePreview(URL.createObjectURL(file));
                } else {
                    setProfileImagePreview(null);
                }
            } else if (name === 'additionalImages') {
                const newFiles = Array.from(files);
                setVendorData(prevData => ({
                    ...prevData,
                    additionalImages: [...prevData.additionalImages, ...newFiles]
                }));
    
                // Generate previews for new additional images
                const newPreviews = newFiles.map(file => URL.createObjectURL(file));
                setAdditionalImagePreviews(prevPreviews => [...prevPreviews, ...newPreviews]);
            }
        } else if (type === 'checkbox') {
            const field = name === 'stageTypes' ? 'stageTypes' : 'settings';
            const updatedOptions = checked
                ? [...vendorData[field], value]
                : vendorData[field].filter(option => option !== value);
            setVendorData({ ...vendorData, [field]: updatedOptions });
        } else if (name === 'capacity') {
            const roundedValue = Math.round(value / 50) * 50;
            setVendorData({ ...vendorData, [name]: roundedValue.toString() });
        } else {
            setVendorData({ ...vendorData, [name]: value });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
    
        for (const key in vendorData) {
            if (key === 'settings' || key === 'stageTypes') {
                formData.append(key, JSON.stringify(vendorData[key]));
            } else if (key === 'latitude' || key === 'longitude') {
                formData.append(key, vendorData[key] !== null ? vendorData[key].toString() : '');
            } else if (key === 'additionalImages') {
                vendorData.additionalImages.forEach(file => {
                    formData.append('additional_images', file); // Append each additional image
                });
            } else {
                formData.append(key, vendorData[key]);
            }
        }
    
        formData.append('type', 'vendor'); // Ensure 'type' is set to 'vendor'
    
        try {
            const response = await axios.post('https://api.live-and-local.com/profile', formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Profile Created:', response.data);
            alert('Profile created successfully!');
            navigate('/login');
        } catch (error) {
            console.error('Failed to create profile:', error);
            alert('Failed to create profile, please check the data and try again.');
            if (error.response && error.response.data) {
                console.error('Error details:', error.response.data);
            }
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
            if (stageDropdownRef.current && !stageDropdownRef.current.contains(event.target)) {
                setIsStageDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        return () => {
            // Revoke profile image preview URL
            if (profileImagePreview) URL.revokeObjectURL(profileImagePreview);

            // Revoke additional images preview URLs
            additionalImagePreviews.forEach(src => URL.revokeObjectURL(src));
        };
    }, [profileImagePreview, additionalImagePreviews]);


    return (
        <div className="wrapper">
            <form onSubmit={handleSubmit}>
                <h1>Create Vendor Profile</h1>
                <div className="input-box">
                    <Tooltip title={instructions.name} arrow>
                        <InfoIcon className="info-icon" fontSize="small" />
                    </Tooltip>
                    <input type="text" name="name" value={vendorData.name} onChange={handleChange} placeholder="Venue Name" required />
                </div>
                <div className="input-box">
                    <Tooltip title={instructions.preferredContactMethod} arrow>
                        <InfoIcon className="info-icon" fontSize="small" />
                    </Tooltip>
                    <select name="preferredContactMethod" value={vendorData.preferredContactMethod} onChange={handleChange} required>
                        <option value="" disabled>Preferred Contact Method</option>
                        <option value="email">Email</option>
                        <option value="phone">Phone</option>
                    </select>
                </div>
                
                {vendorData.preferredContactMethod === 'phone' && (
                    <div className="input-box">
                        <input type="tel" name="phoneNumber" value={vendorData.phoneNumber} onChange={handleChange} placeholder="Phone Number" required />
                    </div>
                )}
                <Tooltip title={instructions.location} arrow>
                        <InfoIcon className="info-icon" fontSize="small" />
                </Tooltip>
                <button type="button" onClick={handleAddressConfirmation}>
                    Add Address to Map
                </button>
                <div className="input-box">
                    <Tooltip title="Upload your main profile image. This will be the primary image displayed on your profile." arrow>
                        <InfoIcon className="info-icon" fontSize="small" />
                    </Tooltip>
                    <input
                        type="file"
                        name="image"
                        onChange={handleChange}
                        accept="image/*"
                    />
                </div>
                <div className="input-box">
                    <Tooltip title="Upload additional images to showcase your venue. These images will appear on your profile gallery." arrow>
                        <InfoIcon className="info-icon" fontSize="small" />
                    </Tooltip>
                    <input
                        type="file"
                        name="additionalImages"
                        onChange={handleChange}
                        placeholder="Additional Images"
                        accept="image/*"
                        multiple // Allows multiple file selection
                    />
                </div>

                {/* Additional Images Previews */}
                {additionalImagePreviews.length > 0 && (
                    <div className="additional-image-previews">
                        {additionalImagePreviews.map((src, index) => (
                            <img key={index} src={src} alt={`Additional Preview ${index + 1}`} width="100" />
                        ))}
                    </div>
                )}
                <div className="input-box dropdown-checkbox" ref={dropdownRef}>
                    <Tooltip title={instructions.settings} arrow>
                        <InfoIcon className="info-icon" fontSize="small" />
                    </Tooltip>
                    <div className="dropdown-header" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        {vendorData.settings.length > 0 ? `${vendorData.settings.length} selected` : 'Select Settings'}
                        <span className={`arrow ${isDropdownOpen ? 'open' : ''}`}></span>
                    </div>
                    {isDropdownOpen && (
                        <div className="dropdown-content">
                            {settingOptions.map((setting) => (
                                <div key={setting} className="checkbox-item">
                                    <input
                                        type="checkbox"
                                        id={setting}
                                        name="settings"
                                        value={setting}
                                        checked={vendorData.settings.includes(setting)}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor={setting}>{setting}</label>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="input-box dropdown-checkbox" ref={stageDropdownRef}>
                    <Tooltip title={instructions.stageTypes} arrow>
                        <InfoIcon className="info-icon" fontSize="small" />
                    </Tooltip>
                    <div className="dropdown-header" onClick={() => setIsStageDropdownOpen(!isStageDropdownOpen)}>
                        {vendorData.stageTypes.length > 0 ? `${vendorData.stageTypes.length} stage types selected` : 'Select Stage Types'}
                        <span className={`arrow ${isStageDropdownOpen ? 'open' : ''}`}></span>
                    </div>
                    {isStageDropdownOpen && (
                        <div className="dropdown-content">
                            {stageOptions.map((stageType) => (
                                <div key={stageType} className="checkbox-item">
                                    <input
                                        type="checkbox"
                                        id={stageType}
                                        name="stageTypes"
                                        value={stageType}
                                        checked={vendorData.stageTypes.includes(stageType)}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor={stageType}>{stageType}</label>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="input-box capacity-slider">
                    <Tooltip title={instructions.capacity} arrow>
                        <InfoIcon className="info-icon" fontSize="small" />
                    </Tooltip>
                    <label htmlFor="capacity">Capacity: {vendorData.capacity}</label>
                    <input
                        type="range"
                        id="capacity"
                        name="capacity"
                        min="0"
                        max="500"
                        step="50"
                        value={vendorData.capacity}
                        onChange={handleChange}
                    />
                </div>
                <div className="input-box">
                    <Tooltip title={instructions.bio} arrow>
                        <InfoIcon className="info-icon" fontSize="small" />
                    </Tooltip>
                    <textarea name="bio" value={vendorData.bio} onChange={handleChange} placeholder="Bio" />
                </div>
                <div className="input-box">
                    <Tooltip title={instructions.other_notes} arrow>
                        <InfoIcon className="info-icon" fontSize="small" />
                    </Tooltip>
                    <textarea name="amenities" value={vendorData.amenities} onChange={handleChange} placeholder="Other Notes" />
                </div>
                <button type="submit" className="btn">Create Profile</button>
            </form>
            <AddressConfirmationModal
                isOpen={isAddressModalOpen}
                onClose={() => setIsAddressModalOpen(false)}
                onConfirm={handleConfirmAddress}
                initialAddress={vendorData.location}
            />
        </div>
    );
}

export default VendorProfileForm;