import axios from "axios";
import { Button } from 'react-bootstrap';

function ClearDatabase(){
    const handleClearDatabase = async () => {
        try {
            const response = await axios.post('https://api.live-and-local.com/clear-db', {}, {
                withCredentials: true  // if you're using sessions
            });
            alert('Database cleared successfully!');
        } catch (error) {
            alert('Failed to clear database: ' + error.message);
        }
    };

    return (
        <Button onClick={handleClearDatabase}>Clear Database</Button>
    );
}

export default ClearDatabase;