import React, { useContext } from 'react';
import { DataContext } from './DataContext';  // Adjust this import path if necessary
import CategoryPreview from './CategoryPreview';
import VendorMap from './Map';
import ClearDBButton from './killbutton';

function Home() {
    const { user, performers, venues } = useContext(DataContext); // Assuming 'user' is also part of the context
    console.log(user);
    return (
        <div>
            {user ? (
                user.type === 'vendor' ? (
                    <CategoryPreview title="Available Performers" items={performers} />
                ) : (
                    <CategoryPreview title="Top Venues" items={venues} />
                )
            ) : (
                <>
                    <CategoryPreview title="Featured Performers" items={performers} />
                    <CategoryPreview title="Popular Venues" items={venues} />
                </>
            )}
            <VendorMap />
        </div>
    );
}

export default Home;