import React, { useState, useEffect  } from 'react';
import { 
  Box, Typography, TextField, Select, MenuItem, Button, 
  FormControl, InputLabel, Chip, Checkbox, ListItemText, OutlinedInput, Snackbar, Alert
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './ImpVenueBookingForm.css';

const VenueBookingForm = ({  userId: propUserId  }) => {
  const navigate = useNavigate();
  const { id } = useParams(); 

  const { user_id } = useParams()
  const [userId, setUserId] = useState(propUserId);

  const [venueProfile, setVenueProfile] = useState({
    setting: [],
    capacity: '',
    location: '',
    stageTypes: [],
  });

  const [booking, setBooking] = useState({
    setting: '',
    startDateTime: null,
    endDateTime: null,
    performanceType: '',
    experienceLevel: '',
    genres: [],
    priceRange: { min: '', max: '' },
  });
  const handleGenreChange = (event) => {
    const { value } = event.target;
    setBooking(prev => ({
      ...prev,
      genres: typeof value === 'string' ? value.split(',') : value,
    }));
  };


  const listGenres = [
    'Rock',
    'Pop',
    'Jazz',
    'Blues',
    'Hip-Hop',
    'Country',
    'EDM (Electronic Dance Music)',
    'Folk',
    'Reggae',
    'Classical',
    'Indie',
    'R&B/Soul',
    'Metal',
    'World Music',
    'Other'
  ];

  
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const settingOptions = ['Bar', 'Nightclub', 'Café', 'Restaurant', 'Lounge', 'Concert Hall','Outdoor Space', 'Brewery/Tavern', 'Private Event Space', 'Other' ];
  
  useEffect(() => {
    if (!userId && id) {
      axios.get(`https://api.live-and-local.com/get-profile/${id}`)
        .then(response => {
          if (response.data && response.data.profile_id) {
            setUserId(response.data.profile_id);
            console.log('Fetched userId:', response.data.profile_id);
          } else {
            console.error('Profile ID not found in response:', response.data);
          }
        })
        .catch(error => {
          console.error('Error getting profile:', error);
        });
    }
  }, [userId, id]);

  // Effect to fetch venue profile data once userId is available
  useEffect(() => {
    if (userId) {
      axios.get(`https://api.live-and-local.com/create-date/${userId}`)
        .then(response => {
          const data = response.data;
          // Assuming the response structure matches what you expect
          const settingArray = Array.isArray(data.setting) ? data.setting : 
                              (typeof data.setting === 'string' ? [data.setting] : []);
          
          setVenueProfile({
            setting: settingArray,
            capacity: data.capacity || '',
            location: data.location || '',
            stageTypes: Array.isArray(data.stageTypes) ? data.stageTypes : [],
          });

          // Update the booking state if necessary
          setBooking(prev => ({
            ...prev,
            setting: Array.isArray(data.setting) ? data.setting[0] : '',
          }));
        })
        .catch(error => {
          console.error('Error fetching profile data:', error);
        });
    }
  }, [userId]);

  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setBooking(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDateTimeChange = (newValue, field) => {
    setBooking(prev => ({ ...prev, [field]: newValue }));
  };


  const handlePriceRangeChange = (event) => {
    const { name, value } = event.target;
    setBooking(prev => ({
      ...prev,
      priceRange: {
        ...prev.priceRange,
        [name]: value,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formatDateTime = (dateTime) => {
      if (dateTime) {
        return dateTime.toISOString().slice(0, -1);
      }
      return null;
    };
    // Prepare booking data to send to backend, including venueProfile data
    const bookingData = {
      ...booking,
      startDateTime: formatDateTime(booking.startDateTime),
      endDateTime: formatDateTime(booking.endDateTime),
      priceRange: {
        min: booking.priceRange.min || null,
        max: booking.priceRange.max || null,
      },
      // Include venueProfile data
      venueProfile: {
        setting: venueProfile.setting,
        capacity: venueProfile.capacity,
        location: venueProfile.location,
      },
    };
  
    // Send booking data to backend
    fetch(`https://api.live-and-local.com/create-date/${userId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bookingData),
      credentials: 'include', // Include this if you're using sessions/cookies for authentication
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to save booking');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Booking saved:', data);
        setOpenSnackbar(true);
        // Optional: Clear form or reset state here
        setTimeout(() => {
          navigate('home'); // Redirect to dashboard after 2 seconds
        }, 2000);
      })
      .catch((error) => {
        console.error('Error saving booking:', error);
        // Handle error (e.g., show an error message)
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box component="form" onSubmit={handleSubmit} className="venue-booking-form">
        <Typography variant="h4" gutterBottom>Post a Booking</Typography>
        
        <FormControl fullWidth margin="normal">
          <InputLabel sx={{ color: '#333' }}>Setting</InputLabel>
          <Select
            name="setting"
            value={venueProfile.setting}
            onChange={handleChange}
            label="Setting"
          >
            {venueProfile.setting.map((setup) => (
              <MenuItem key={setup} value={setup}>{setup}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <DateTimePicker
            label="Start Date & Time"
            value={booking.startDateTime}
            onChange={(newValue) => handleDateTimeChange(newValue, 'startDateTime')}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
          <DateTimePicker
            label="End Date & Time"
            value={booking.endDateTime}
            onChange={(newValue) => handleDateTimeChange(newValue, 'endDateTime')}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Box>

        <FormControl fullWidth margin="normal">
          <TextField
            label="Capacity"
            value={venueProfile.capacity}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <TextField
            label="Location"
            value={venueProfile.location}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel sx={{ color: '#333' }}>Performance Type</InputLabel>
          <Select
            name="performanceType"
            value={booking.performanceType}
            onChange={handleChange}
            label="Performance Type"
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#ddd',
                borderRadius: '8px',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#EF6191',
                backgroundColor: '#fcf2f5',
              },
              '& .MuiSelect-select': {
                padding: '10px 20px',
              },
              fontSize: '16px',
              color: '#333',
            }}
          >
            <MenuItem value="solo">Solo Artist</MenuItem>
            <MenuItem value="band">Band</MenuItem>
            <MenuItem value="dj">DJ</MenuItem>
            <MenuItem value="duo">Duo</MenuItem>
            <MenuItem value="ensemble">Ensemble</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel sx={{ color: '#333' }}>Experience Level</InputLabel>
          <Select
            name="experienceLevel"
            value={booking.experienceLevel}
            onChange={handleChange}
            label="Experience Level"
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#ddd',
                borderRadius: '8px',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#EF6191',
                backgroundColor: '#fcf2f5',
              },
              '& .MuiSelect-select': {
                padding: '10px 20px',
              },
              fontSize: '16px',
              color: '#333',
            }}
          >
            <MenuItem value="beginner">Beginner (1-3 years)</MenuItem>
            <MenuItem value="intermediate">Intermediate (3-5 years)</MenuItem>
            <MenuItem value="experienced">Experienced (5-10 years)</MenuItem>
            <MenuItem value="professional">Professional (10+ years)</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel sx={{ color: '#333' }}>Genres</InputLabel>
          <Select
            multiple
            name="genres"
            value={booking.genres}
            onChange={handleGenreChange}
            input={<OutlinedInput label="Genres" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} sx={{
                    backgroundColor: '#EF6191',
                    color: '#fff',
                  }} />
                ))}
              </Box>
            )}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#ddd',
                borderRadius: '8px',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#EF6191',
                backgroundColor: '#fcf2f5',
              },
              '& .MuiSelect-select': {
                padding: '10px 20px',
              },
              fontSize: '16px',
              color: '#333',
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  borderRadius: 8,
                  border: '1px solid #EF6191',
                },
              },
            }}
          >
            {listGenres.map((genre) => (
              <MenuItem key={genre} value={genre}>
                <Checkbox
                  checked={booking.genres.indexOf(genre) > -1}
                  sx={{
                    color: '#EF6191',
                    '&.Mui-checked': {
                      color: '#EF6191',
                    },
                  }}
                />
                <ListItemText primary={genre} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            fullWidth
            label="Min Price"
            name="min"
            type="number"
            value={booking.priceRange.min}
            onChange={handlePriceRangeChange}
            InputProps={{ startAdornment: '$' }}
          />
          <TextField
            fullWidth
            label="Max Price"
            name="max"
            type="number"
            value={booking.priceRange.max}
            onChange={handlePriceRangeChange}
            InputProps={{ startAdornment: '$' }}
          />
        </Box>

        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          fullWidth
          sx={{
            backgroundColor: '#EF6191',
            borderRadius: '8px',
            height: '50px',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#c45577',
            },
            transition: 'background-color 0.3s',
          }}
        >
          Post Booking
        </Button>
      </Box>
      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Your booking has been successfully posted!
        </Alert>
      </Snackbar>
    </LocalizationProvider>
  );
};

export default VenueBookingForm;