import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext'; // Make sure this import is correct
import './Messages.css';

const MessageContent = ({ content }) => {
    const navigate = useNavigate();  // Add this

    const processMessageContent = (text) => {
      if (text.includes('New bid received for your event!')) {
        // Extract all the bid details using regex
        const venueMatch = text.match(/Venue: ([^\n]+)/);
        const dateMatch = text.match(/Date: ([^\n]+)/);
        const timeMatch = text.match(/Time: ([^\n]+)/);
        const locationMatch = text.match(/Location: ([^\n]+)/);
        const bidAmountMatch = text.match(/Bid Amount: \$([^\n]+)/);
        const technicalMatch = text.match(/Technical Requirements: ([^\n]+)/);
        const artistNotesMatch = text.match(/Artist Notes: ([^\n]+)/);
        const contractMatch = text.match(/\/contracts\/(\d+)/);
  
        return (
          <div className="bg-white rounded-lg p-4 shadow-sm border border-gray-200">
            <div className="flex items-center mb-3">
              <div className="w-2 h-2 bg-pink-500 rounded-full mr-2"></div>
              <h3 className="text-lg font-semibold text-gray-800">New Bid Received!</h3>
            </div>
  
            <div className="space-y-2 text-left">
              {venueMatch && (
                <div className="flex">
                  <span className="text-gray-600 w-32">Venue:</span>
                  <span className="text-gray-800">{venueMatch[1]}</span>
                </div>
              )}
              
              {dateMatch && timeMatch && (
                <div className="flex">
                  <span className="text-gray-600 w-32">Schedule:</span>
                  <span className="text-gray-800">
                    {dateMatch[1].split('GMT')[0]} • {timeMatch[1]}
                  </span>
                </div>
              )}
              
              {locationMatch && (
                <div className="flex">
                  <span className="text-gray-600 w-32">Location:</span>
                  <span className="text-gray-800">{locationMatch[1]}</span>
                </div>
              )}
              
              {bidAmountMatch && (
                <div className="flex">
                  <span className="text-gray-600 w-32">Bid Amount:</span>
                  <span className="text-gray-800 font-medium">${bidAmountMatch[1]}</span>
                </div>
              )}
              
              {technicalMatch && (
                <div className="flex">
                  <span className="text-gray-600 w-32">Technical:</span>
                  <span className="text-gray-800">{technicalMatch[1]}</span>
                </div>
              )}
              
              {artistNotesMatch && (
                <div className="flex">
                  <span className="text-gray-600 w-32">Notes:</span>
                  <span className="text-gray-800">{artistNotesMatch[1]}</span>
                </div>
              )}
  
            {contractMatch && (
                <div className="mt-4">
                    <button
                    onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/contracts/${contractMatch[1]}`);  // Use this instead of window.location.href
                    }}
                    className="w-full px-4 py-2 bg-pink-600 text-white rounded-md hover:bg-pink-700 transition-colors duration-200"
                >
                    View Contract Details
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      }
  

      const contractLinkRegex = /\/contracts\/(\d+)/g;
      const parts = text.split(contractLinkRegex);
      
      return parts.map((part, index) => {
        if (index % 2 === 0) {
          return part;
        } else {
          return (
            <button
              key={`contract-${part}`}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/contracts/${part}`);  // Use this instead of window.location.href
              }}
              className="inline-block px-4 py-2 bg-pink-600 text-white rounded-md hover:bg-pink-700 transition-colors duration-200 my-2"
            >
              View Contract Details
            </button>
          );
        }
      });
    };

  
    return (
      <div className="message-content">
        {processMessageContent(content)}
      </div>
    );
  };

const Messages = () => {
    const { user } = useUser();
    const [profiles, setProfiles] = useState([]);
    const [messages, setMessages] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [newMessage, setNewMessage] = useState('');

    const fetchProfiles = useCallback(async () => {
        if (!user) {
            console.error("User is not defined");
            return;
        }

        try {
            const response = await axios.get('https://api.live-and-local.com/messages/latest', { withCredentials: true });
            console.log("Response data: ", response.data);

            const uniqueProfiles = {};
            response.data.messages.forEach(message => {
                const otherUserId = message.sender_id === user.user_id ? message.receiver_id : message.sender_id;
                const uniqueKey = `${otherUserId}-${message.other_user_name}`;

                if (!uniqueProfiles[uniqueKey]) {
                    uniqueProfiles[uniqueKey] = {
                        senderId: message.sender_id,
                        receiverId: message.receiver_id,
                        otherUserName: message.other_user_name,
                        content: message.content,
                        timestamp: message.timestamp,
                    };
                } else {
                    if (new Date(message.timestamp) > new Date(uniqueProfiles[uniqueKey].timestamp)) {
                        uniqueProfiles[uniqueKey] = {
                            senderId: message.sender_id,
                            receiverId: message.receiver_id,
                            otherUserName: message.other_user_name,
                            content: message.content,
                            timestamp: message.timestamp,
                        };
                    }
                }
            });

            const profilesArray = Object.values(uniqueProfiles);
            console.log("Unique profiles: ", profilesArray);

            setProfiles(profilesArray);
        } catch (error) {
            console.error('Failed to fetch profiles:', error);
        }
    }, [user]);

    useEffect(() => {
        fetchProfiles();
    }, [fetchProfiles]);

    const fetchMessages = async (senderId, receiverId) => {
        if (user.user_id === receiverId) {
            receiverId = senderId
            senderId = user.user_id
        }
        setSelectedProfile(receiverId)
        try {
            const response = await axios.get(`https://api.live-and-local.com/messages/conversation/${senderId}/${receiverId}`, { withCredentials: true });
            console.log('Here',response)
            setMessages(response.data.messages);
        } catch (error) {
            console.error('Failed to fetch messages:', error);
        }
    };

    const handleSendMessage = async () => {
        if (!selectedProfile) {
            console.error('No profile selected');
            return;
        }
        try {
            const response = await axios.post('https://api.live-and-local.com/contact', {
                contactDetails: newMessage,
                senderID: user.user_id,
                recipientID: selectedProfile
            }, { withCredentials: true });
            if (response.status === 201) {
                const { sender_name, content, timestamp } = response.data;
                setMessages([...messages, { content, timestamp, sender_id: user.user_id, sender_name }]);
                setNewMessage('');
                fetchProfiles();
            }
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const confirmBooking = async (dateId, receiverId) => {
        try {
            const response = await fetch('https://api.live-and-local.com/confirm-booking', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ date_id: dateId, receiver_id: receiverId }),
                credentials: 'include'
            });
    

            const data = await response.json();
            alert(data.message);
            if (response.status === 200) {
                // Update the message status in the state instead of reloading
                setMessages(messages.map(msg => {
                    if (msg.date_id === dateId) {
                        return { ...msg, status: 'booked' };
                    }
                    fetchProfiles();
                    return msg;
                }));
            }
        } catch (error) {
            console.error('Error confirming booking:', error);
            alert('Error confirming booking');
        }
    };

    const denyBooking = async (dateId) => {
        try {
            const response = await fetch('https://api.live-and-local.com/deny-booking', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ date_id: dateId }),
                credentials: 'include'
            });

            const data = await response.json();
            alert(data.message);
            if (response.status === 200) {
                // Update the message status in the state instead of reloading
                setMessages(messages.map(msg => {
                    if (msg.date_id === dateId) {
                        return { ...msg, status: 'available' };
                    }
                    fetchProfiles();
                    return msg;
                }));
            }
        } catch (error) {
            console.error('Error denying booking:', error);
            alert('Error denying booking');
        }
    };

    const handleButtonClick = async (messageId, event) => {
        const dateId = event.target.getAttribute('data-date-id');
        const receiverId = event.target.getAttribute('data-receiver-id');
        try {
            fetch('https://api.live-and-local.com/update_booking_message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ messageId })
            });
        } catch (error) {
            console.error('Error processing message:', error);
        }
        if (!dateId || !receiverId) return; // Check both IDs are present
        if (event.target.classList.contains('confirm-button')) {
            confirmBooking(dateId, receiverId);
        } else if (event.target.classList.contains('deny-button')) {
            denyBooking(dateId, receiverId);
        }
    };

    if (!user) {
        return <div className="messages-container">Loading...</div>;
    }

    return (
        <div className="messages-container">
          <div className="profiles-list">
            {profiles.map((profile, index) => (
              <div
                key={index}
                className={`profile-item ${
                  selectedProfile === profile.senderId || selectedProfile === profile.receiverId ? 'selected' : ''
                }`}
                onClick={() => fetchMessages(profile.senderId, profile.receiverId)}
              >
                <p><strong>{profile.otherUserName}:</strong> {profile.content}</p>
                <span>{new Date(profile.timestamp).toLocaleString()}</span>
              </div>
            ))}
          </div>
          <div className="messages-list">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`message-item ${msg.sender_id === user.user_id ? 'sent' : 'received'}`}
                onClick={(event) => handleButtonClick(msg.message_id, event)}
              >
                <p>
                  <strong>{msg.sender_name}:</strong><br/>
                  <MessageContent content={msg.content} />
                </p>
                <span>{new Date(msg.timestamp).toLocaleString()}</span>
              </div>
            ))}
            {selectedProfile && (
              <div className="message-input flex items-center p-4">
                <input 
                  type="text" 
                  value={newMessage} 
                  onChange={(e) => setNewMessage(e.target.value)} 
                  placeholder="Type a message..."
                  className="flex-grow mr-4 p-2 border rounded-md"
                />
                <button 
                  onClick={handleSendMessage}
                  className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200"
                >
                  Send
                </button>
              </div>
            )}
          </div>
        </div>
      );
    };
    
    export default Messages;