import React, { useState, useMemo, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';
import Modal from './DateModel'; // Ensure this modal is correctly implemented
import axios from 'axios';

const AvailableDatesPicker = ({ availableDates, bookedDates, interestedDates, setAvailableDates, profileId, isEditable = false, userType, isViewProfile }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [localAvailableDates, setLocalAvailableDates] = useState([]);
    const initialFetchRef = useRef(true);

    const parseDate = (dateString) => {
        if (!dateString || typeof dateString !== 'string') return null
        console.log('PPARSED 1:', dateString);
        const date = new Date(dateString);

        console.log('PPARSED 2:', date);
        const adjustedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        console.log('PPARSED 3:', adjustedDate);
        return isNaN(adjustedDate.getTime()) ? null : adjustedDate;
    };


    const mergeDates = () => {
        const logAndParseDate = (dateString, type) => {
            const parsedDate = parseDate(dateString);
            return parsedDate;
        };

        const parsedAvailableDates = availableDates.map(entry => ({
            ...entry,
            date: logAndParseDate(entry.date, 'available'),
            start_time: entry.start_time || '',
            end_time: entry.end_time || '',
        }));

        const parsedBookedDates = (bookedDates || []).map(entry => ({
            ...entry,
            date: logAndParseDate(entry.date, 'booked'),
            start_time: entry.start_time || '',
            end_time: entry.end_time || '',
        }));

        const parsedInterestedDates = (interestedDates || []).map(entry => ({
            ...entry,
            date: logAndParseDate(entry.date, 'interested'),
            start_time: entry.start_time || '',
            end_time: entry.end_time || '',
        }));

        return [...parsedAvailableDates, ...parsedBookedDates, ...parsedInterestedDates];
    };

    useEffect(() => {
        if (initialFetchRef.current) {
            initialFetchRef.current = false;
            const mergedDates = mergeDates();
            setLocalAvailableDates(mergedDates);
            setAvailableDates(mergedDates.filter(entry => entry.status === 'available'));
        }
    }, [availableDates, bookedDates, interestedDates]);

    const handleDateSelect = (date) => {
        if (isEditable && date && !isNaN(date.valueOf())) {
            setSelectedDate(date);
            setModalOpen(true);
        }
    };

    const handleSaveDetails = async ({ startTime, endTime, description, ...additionalFields }) => {
        if (!isEditable) return;

        const formattedDate = selectedDate.toISOString().split('T')[0];
        const newDateEntry = {
            profileId,
            date: formattedDate,
            startTime,
            endTime,
            description,
            ...additionalFields
        };

        try {
            const response = await axios.post('https://api.live-and-local.com/save-date', newDateEntry, { withCredentials: true });
            if (response.status === 200) {
                const updatedDates = [...localAvailableDates, {
                    ...response.data,
                    date: new Date(response.data.date),
                    start_time: response.data.start_time,
                    end_time: response.data.end_time,
                    status: 'available'
                }];
                setLocalAvailableDates(updatedDates);
                setAvailableDates(updatedDates);
            }
        } catch (error) {
            console.error('Error saving date details:', error);
        }
        setModalOpen(false);
    };

    const removeDate = async (index) => {
        if (!isEditable) return;

        const dateToRemove = localAvailableDates[index];

        try {
            const response = await axios.delete('https://api.live-and-local.com/remove-date', {
                data: {
                    profileId: profileId,
                    date: dateToRemove.date.toISOString(),
                    startTime: dateToRemove.start_time,
                    endTime: dateToRemove.end_time
                },
                withCredentials: true
            });

            if (response.status === 200) {
                const newDates = localAvailableDates.filter((_, idx) => idx !== index);
                setLocalAvailableDates(newDates);
                setAvailableDates(newDates);
            }
        } catch (error) {
            console.error('Error removing date:', error);
        }
    };

    const highlightedDates = useMemo(() => {
        return availableDates.map(ad => {
            console.log('Try Cug', ad);
            console.log('Try Cug', ad.date);
            
            let dateToUse;
            if (isViewProfile) {
                console.log('Using ad.date:', ad.date);
                dateToUse = ad.date;
            } else {
                console.log('Using ad:', ad);
                dateToUse = ad.date;
            }
            
            let parsedDate;
            if (dateToUse instanceof Date) {
                parsedDate = dateToUse;
            } else {
                parsedDate = parseDate(dateToUse);
            }
            
            console.log('HOPEFULL LOGS: ', parsedDate);
            return parsedDate;
        }).filter(date => date !== null);
    }, [availableDates, isViewProfile]);

    const holidayDates = useMemo(() => {
        return (bookedDates || []).map(bd => {
            const date = parseDate(bd.date);
            return { date: date, holidayName: bd.holidayName || 'Unknown Holiday' };
        }).filter(date => date !== null);
    }, [bookedDates]);


    console.log('Highlighted Dates for DatePicker:', highlightedDates);
    console.log('Holiday Dates for DatePicker:', holidayDates);
    return (
        <div className="date-picker-container">
            <DatePicker 
                selected={selectedDate}
                onChange={handleDateSelect}
                inline
                highlightDates={highlightedDates}
                className="date-picker"
                holidays={holidayDates}
                placeholderText="This display holidays"
            />
            
            {(isViewProfile ? localAvailableDates : availableDates).map((entry, index) => {
                return (
                    <div className="date-entry" key={index}>
                        <p className="date-details">
                            Date: {isViewProfile 
                                ? (entry.date instanceof Date ? entry.date.toDateString() : 'Invalid Date') 
                                : (entry.date instanceof Date ? entry.date.toDateString() : entry.date)} -
                            Time: {entry.start_time || 'N/A'} to {entry.end_time || 'N/A'} -
                            Description: {entry.description || 'N/A'} -
                            Status: {entry.status || 'N/A'}
                        </p>
                        {isEditable && <button onClick={() => removeDate(index)} className="remove-date-range">Remove</button>}
                    </div>
                );
            })}
            {modalOpen && selectedDate && (
                <Modal
                    date={selectedDate}
                    userType={userType}
                    onSave={handleSaveDetails}
                    onCancel={() => setModalOpen(false)}
                />
            )}
        </div>
    );
};

export default AvailableDatesPicker;