import React, { useContext } from 'react';
import { DataContext } from './DataContext';
import CategoryPreview from './CategoryPreview';

function VendorsComponent() {
    const { venues } = useContext(DataContext);
    return (
        <div>
            <h1>Vendors</h1>
            <CategoryPreview title="All Vendors" items={venues} />
        </div>
    );
}

export default VendorsComponent;