import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { 
  Event, 
  Person, 
  AttachMoney, 
  Description, 
  Schedule,
  Place,
  CheckCircle,
} from '@mui/icons-material';
import axios from 'axios';

const ContractDashboard = ({ contract, onBack, onContractSigned, page  }) => {
  console.log('CONTRACTS - ',contract);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openSignedContract, setOpenSignedContract] = useState(false);
  const isContractBooked = contract.status.toLowerCase() === 'signed' || contract.status.toLowerCase() === 'booked';
  const calculateDuration = (startTime, endTime) => {
    // Parse the times and create Date objects
    const start = new Date(`2000-01-01T${startTime}:00`);
    let end = new Date(`2000-01-01T${endTime}:00`);
    
    // If end time is before start time, assume it's the next day
    if (end < start) {
      end = new Date(end.getTime() + 24 * 60 * 60 * 1000);
    }
    
    // Calculate the difference in milliseconds
    const diff = end - start;
    
    // Convert to hours and minutes
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    
    // Format the duration string
    return `${hours}h ${minutes}m`;
  };

  const handleSignContract = () => {
    if (!isContractBooked) {
      setOpenConfirmation(true);
    }
  };

  const handleViewSignedCopy = () => {
    setOpenSignedContract(true);
  };

  const handleCloseSignedCopy = () => {
    setOpenSignedContract(false);
  };

  
  const duration = calculateDuration(contract.startTime, contract.endTime);
  console.log(contract.other_party)
  console.log(`Duration: ${duration}`);
  const contractDetails = {
    id: contract?.contract?.id,
    artist: contract?.other_party?.name ?? contract?.contract?.other_party?.name,
    event: contract?.contract?.booking_details?.genres,
    date: contract?.contract?.date,
    status: contract?.status ?? contract?.contract?.status,
    venue: contract?.contract?.booking_details?.venueName,
    fee: contract?.contract?.bid_amount,
    duration: duration,
    equipment: contract?.contract?.artist_notes,
    specialRequirements: contract?.contract?.technical_requirements,
    contractTerms: [
      "Artist will perform a 90-minute set",
      "Sound check at 6 PM",
      "Payment to be made immediately after the performance",
    ],
  };

  console.log(contract);
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'signed':
        return 'success';
      case 'pending':
        return 'warning';
      default:
        return 'default';
    }
  };
  const formatGenres = (genres) => {
    if (Array.isArray(genres)) {
      return genres.join(', ');
    } else if (typeof genres === 'string') {
      // If it's a string, split by potential separators and then join
      return genres.split(/[,;/]/).map(genre => genre.trim()).join(', ');
    }
    return genres; // fallback, just in case
  };

  const handleConfirmSign = async () => {
    try {
      // Update contract status
      console.log(contract.id)
      const response = await axios.post('https://api.live-and-local.com/api/sign-contract', {
        contractId: contract.id,
      });

      if (response.data.success) {
        // Update available date status
        console.log(contract)
        console.log('Contract Details', contract.booking_details);
        await axios.post('https://api.live-and-local.com/api/update-available-date', {
          dateId: contract.booking_id,
          status: 'booked',
        });

        // Send confirmation message to artist
        await axios.post('https://api.live-and-local.com/api/send-message', {
          receiverId: contract.other_party.id,
          content: `Your contract for the event on ${contract.date} has been signed. The agreed fee is ${contract.bid_amount}.`,
        });

        // Close the confirmation dialog
        setOpenConfirmation(false);

        // Update the contract status locally
        onContractSigned(contract.id);
      }
    } catch (error) {
      console.error('Error signing contract:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Button onClick={onBack} variant="outlined" sx={{ mb: 2 }}>
        Back to {page}
      </Button>
      
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={8}>
            <Typography variant="h4" gutterBottom>{contractDetails.artist}</Typography>
            <Typography variant="h6" color="text.secondary">{formatGenres(contractDetails.event)}</Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
            <Chip 
              label={contractDetails.status} 
              color={getStatusColor(contractDetails.status)}
              sx={{ fontSize: '1rem', p: 1 }}
            />
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>Event Details</Typography>
            <List>
              <ListItem>
                <Event sx={{ mr: 2 }} />
                <ListItemText primary="Date" secondary={contractDetails.date} />
              </ListItem>
              <ListItem>
                <Schedule sx={{ mr: 2 }} />
                <ListItemText primary="Duration" secondary={contractDetails.duration} />
              </ListItem>
              <ListItem>
                <Place sx={{ mr: 2 }} />
                <ListItemText primary="Venue" secondary={contractDetails.venue} />
              </ListItem>
              <ListItem>
                <AttachMoney sx={{ mr: 2 }} />
                <ListItemText primary="Fee" secondary={contractDetails.fee} />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>Performance Requirements</Typography>
            <List>
              <ListItem>
                <ListItemText primary="Equipment" secondary={contractDetails.specialRequirements} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Special Requirements" secondary={contractDetails.equipment} />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Contract Terms</Typography>
            <List>
              {contractDetails.contractTerms.map((term, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemText primary={term} />
                  </ListItem>
                  {index < contractDetails.contractTerms.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" color="primary">
          Edit Contract
        </Button>
        {isContractBooked ? (
          <Button 
            variant="contained" 
            color="secondary"
            onClick={handleViewSignedCopy}
          >
            View Signed Copy
          </Button>
        ) : (
          <Button 
            variant="contained" 
            color="secondary"
            onClick={handleSignContract}
          >
            Sign Contract
          </Button>
        )}
      </Box>

      <Dialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Contract Signing"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to sign the contract for {contractDetails.artist} on {contractDetails.date} for ${contractDetails.fee}. 
            Are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmSign} color="primary" autoFocus>
            Confirm and Sign
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSignedContract}
        onClose={handleCloseSignedCopy}
        fullWidth
        maxWidth="md"
        aria-labelledby="signed-contract-dialog-title"
      >
        <DialogTitle id="signed-contract-dialog-title">
          Signed Contract
          <IconButton
            aria-label="close"
            onClick={handleCloseSignedCopy}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            {/* You can add a close icon here if desired */}
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                Artist: {contractDetails.artist}
                <CheckCircle color="success" sx={{ ml: 1 }} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                Venue: {contractDetails.venue}
                <CheckCircle color="success" sx={{ ml: 1 }} />
              </Typography>
            </Grid>
          </Grid>

          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>Event Details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>Date: {contractDetails.date}</Typography>
                <Typography>Duration: {contractDetails.duration}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Venue: {contractDetails.venue}</Typography>
                <Typography>Fee: ${contractDetails.fee}</Typography>
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>Performance Requirements</Typography>
            <Typography>Equipment: {contractDetails.equipment}</Typography>
            <Typography>Special Requirements: {contractDetails.specialRequirements}</Typography>
          </Paper>

          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Contract Terms</Typography>
            <List>
              {contractDetails.contractTerms.map((term, index) => (
                <ListItem key={index}>
                  <ListItemText primary={term} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSignedCopy} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ContractDashboard;