import React, { useState } from 'react';
import { Box, Typography, TextField, Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, IconButton, Chip , CardContent } from '@mui/material';
import { Search, FilterList, MoreVert, Event, AccessTime, Place } from '@mui/icons-material';
import './BookingStylesImp.css';
import ArtistBookingLandingPage from './ArtistBookingLandingPage';
import ArtistDiscover from './ArtistBookingDiscover';
import BookingsList from './ArtistBookingList';
import VendorMap from './Map';
const ArtistBookingHomePage = ({ venueProfile }) => {
    const [currentPage, setCurrentPage] = useState('landing');
    const [selectedBooking, setSelectedBooking] = useState(null)
    const handleViewBookings = () => setCurrentPage('bookingList');
    const handleDiscover = () => setCurrentPage('discover');
    const handleBackToLanding = () => setCurrentPage('landing');
    const handleViewVenues = () => setCurrentPage('localVenues');
    return (
        <Box className="venue-booking-container" sx={{ display: 'flex' }}>
            {/* Sidebar */}
            <Box
                className="venue-sidebar"
                sx={{
                    width: '240px',
                    bgcolor: '#924AAE',
                    color: '#fff',
                    minHeight: '100vh',
                    padding: 2
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        mb: 2,
                        cursor: 'pointer', // Indicates that the header is clickable
                        '&:hover': {
                            textDecoration: 'underline', // Optional: Adds an underline on hover
                        }
                    }}
                    onClick={handleBackToLanding} // Navigates back to the landing page
                >
                    Booking Dashboard
                </Typography>
                <List>
                    {['View Bookings', 'Discover Events', "See Who's Local", 'Finances'].map((text) => (
                        <ListItem
                            button
                            key={text}
                            sx={{ color: '#fff' }}
                            onClick={() => {
                                switch(text) {
                                    case 'View Bookings':
                                        handleViewBookings();
                                        break;
                                    case 'Discover Events':
                                        handleDiscover();
                                        break;
                                    case "See Who's Local":
                                        handleViewVenues();
                                        break;
                                    default:
                                        handleBackToLanding();
                                }
                            }}
                        >
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}
                </List>
            </Box>
            {/* Main content */}
            <Box className="venue-main-content" sx={{ flex: 1, padding: 4 }}>
                {currentPage === 'landing' && (
                    <ArtistBookingLandingPage
                        onViewBookings={handleViewBookings}
                        onFindEvent={handleDiscover}
                    />
                )}
                {currentPage === 'bookingList' && (
                    <BookingsList
                        setSelectedBooking={setSelectedBooking}
                        setCurrentPage={setCurrentPage}
                    />
                )}
                {currentPage === 'discover' && (
                    <ArtistDiscover />
                )}
                {currentPage === "localVenues" && (
                    <VendorMap />
                )}
            </Box>
        </Box>
    );
};
export default ArtistBookingHomePage;