import React, { useState } from 'react';
import './AuthStyles.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function Register() {
    const [userData, setUserData] = useState({
        username: '',
        password: '',
        email: '',
        user_type: 'vendor' // default set to 'vendor'
    });
    const navigate = useNavigate(); // Hook for navigation

    const handleChange = (event) => {
        setUserData({ ...userData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://api.live-and-local.com/register', userData);
            if (response.status === 201) {
                console.log('Registration Response:', response.data);
                console.log('Profile, ID: ', response.data.user_id)
                navigate(`/${response.data.user_type}-profile-form`, { state: { userId: response.data.user_id }});
            }
        } catch (error) {
            console.error('Registration Failed:', error.response.data);
        }
    };


    return (
        <div className="wrapper">
            <form onSubmit={handleSubmit}>
                <h1>Register</h1>
                <div className="input-box">
                    <input
                        type="text"
                        name="username"
                        value={userData.username}
                        onChange={handleChange}
                        placeholder="Username"
                        required
                    />
                    <i className="bx bxs-user"></i>
                </div>
                <div className="input-box">
                    <input
                        type="email"
                        name="email"
                        value={userData.email}
                        onChange={handleChange}
                        placeholder="Email"
                        required
                    />
                    <i className="bx bx-mail-send"></i>
                </div>
                <div className="input-box">
                    <input
                        type="password"
                        name="password"
                        value={userData.password}
                        onChange={handleChange}
                        placeholder="Password"
                        required
                    />
                    <i className="bx bxs-lock-alt"></i>
                </div>
                <div className="input-box">
                    <select name="user_type" value={userData.user_type} onChange={handleChange}>
                        <option value="vendor">Vendor</option>
                        <option value="performer">Performer</option>
                    </select>
                </div>
                <button type="submit" className="btn">Register</button>
            </form>
        </div>
    );
}

export default Register;