import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; 
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./search.css";
import VendorMap from "./Map";

function SearchComponent() {
    const locationHook = useLocation();
    const [searchDate, setSearchDate] = useState(new Date());
    const [location, setLocation] = useState({ lat: null, lon: null });
    const [locationError, setLocationError] = useState(false);
    const [results, setResults] = useState([]);
    const [searchType, setSearchType] = useState('performer');
    const [city, setCity] = useState('');
    const [state, setState] = useState('MA');
    const [nameQuery, setNameQuery] = useState("");
    const [currentTab, setCurrentTab] = useState('date');
    const [genreQuery, setGenreQuery] = useState("");
    const [typeQuery, setTypeQuery] = useState("");
    const [pageNumber, setPageNumber] =useState(5);

    const handleTabChange = (tab) => {
        setCurrentTab(tab);
        setResults([]);  // Clear results when changing tabs
        if (tab === 'location') {
            fetchLocation();
        }
    };

    const genres = [
        'Rock',
        'Pop',
        'Jazz',
        'Blues',
        'Hip-Hop',
        'Country',
        'EDM (Electronic Dance Music)',
        'Folk',
        'Reggae',
        'Classical',
        'Indie',
        'R&B/Soul',
        'Metal',
        'World Music',
        'Other'
    ];
    
    const stateAbbreviations = [
        "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
        "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
        "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
        "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
        "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
    ];

    const performanceTypes = [
        'Solo Act',
        'Duo',
        'Band',
        'DJ',
        'Ensemble'
    ];

    const fetchLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function(position) {
                setLocation({
                    lat: position.coords.latitude,
                    lon: position.coords.longitude
                });
                setLocationError(false);
            }, function(error) {
                console.error("Error Code = " + error.code + " - " + error.message);
                setLocationError(true);
                setLocation.lat = null;
                setLocation.lon = null;
            });
        } else {
            console.error("Geolocation is not supported by this browser.");
            setLocationError(true);
            setLocation.lat = null;
            setLocation.lon = null;
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(locationHook.search);
        const typeParam = params.get('type');
        if (typeParam) {
            setCurrentTab(typeParam);
            if (typeParam === 'genre') {
                const genreParam = params.get('genreQuery');
                console.log(genreParam)
                if (genreParam) {
                    setGenreQuery(genreParam);
                }
            } else if (typeParam === 'name') {
                const nameParam = params.get('nameQuery');
                if (nameParam) {
                    setNameQuery(nameParam);
                }
            } else if (typeParam === 'type') {
                const typeParam = params.get('typeQuery');
                if (typeParam) {
                    setTypeQuery(typeParam);
                }
            }
            // Handle other types if necessary
        }
    }, [locationHook.search]);
    

    const handleLocationInput = () => {
        setLocationError(true);
        setLocation({
            lat: null,
            lon: null
        });

    };

    const handleSearch = async () => {
        let url = 'https://api.live-and-local.com/search';
        let params = {};
        console.log('CuRREENT tab,', currentTab);
        if (currentTab === 'date') {
            const utcDate = new Date(searchDate.getTime() - (searchDate.getTimezoneOffset() * 60000));
            params = { date: utcDate.toISOString().split('T')[0], type: searchType };
        } else if (currentTab === 'location') {
            const distance = document.getElementById('distance').value;
            const latitude = location.lat
            const longitude = location.lon
            const type = "location"
            if (location.lat === null) {
                let location_type = "region";
                const city = document.getElementById('city').value;
                const state = document.getElementById('stateSelect').value;
                params = {type, location_type, city, state, distance}
            }
            else {
                let location_type = "coord"
                params = {type, location_type, distance, lat: latitude || undefined, lon: longitude || undefined};
            }
        } else if (currentTab === 'name'){
            const nameQuery = document.getElementById('nameQuery').value;
            setNameQuery(nameQuery);
            const type = 'name';
            params = {type, nameQuery, pageNumber};
        } else if (currentTab === 'genre') {
            const genreQuery = document.getElementById('genreQuery').value;
            console.log(genreQuery);
            setGenreQuery(genreQuery);
            console.log('GENRE QUERY: ', genreQuery)
            const type = 'genre';
            params = {type, genreQuery, pageNumber};
        }else if (currentTab === 'type') {
            const typeQuery = document.getElementById('typeQuery').value;
            console.log(typeQuery);
            setTypeQuery(typeQuery);
            console.log('GENRE QUERY: ', typeQuery)
            const type = 'type';
            params = {type, typeQuery, pageNumber};
        }
        try {
            const response = await axios.get(url, { params });
            setResults(response.data.results);
            console.log(results);
            setLocation({
                lat: response.data.latitude,
                lon: response.data.longitude
            });
        } catch (error) {
            console.error('Search failed:', error);
        }
    };

    const nextPage = () => {
        setPageNumber(pageNumber + 5);
    };

    useEffect(() => {
        if (currentTab === 'name' || currentTab === 'genre' || currentTab === 'type') {
            handleSearch();
        }
    }, [pageNumber, nameQuery, genreQuery, typeQuery]);

    const textEntered = (e) => {
        setNameQuery(e.target.value); // Update nameQuery with the input value
        if (pageNumber !== 5) {
            setPageNumber(5); // Only set to 5 if it's not already 5
        }
    };

    const genreSelected = (e) => {
        setGenreQuery(e.target.value); // Update genreQuery with the selected value
        if (pageNumber !== 5) {
            setPageNumber(5);
        }
    };

    const typeSelected = (e) => {
        setTypeQuery(e.target.value); // Update genreQuery with the selected value
        if (pageNumber !== 5) {
            setPageNumber(5);
        }
    };


    return (
        <div className="search-container">
            <div className="tabs">
                <button
                    className={`tab ${currentTab === 'date' ? 'selected' : ''}`}
                    onClick={() => handleTabChange('date')}>
                    Date
                </button>
                <button
                    className={`tab ${currentTab === 'location' ? 'selected' : ''}`}
                    onClick={() => handleTabChange('location')}>
                    Location
                </button>
                <button
                    className={`tab ${currentTab === 'name' ? 'selected' : ''}`}
                    onClick={() => handleTabChange('name')}>
                    Name
                </button>
                <button
                    className={`tab ${currentTab === 'genre' ? 'selected' : ''}`}
                    onClick={() => handleTabChange('genre')}>
                    Genre
                </button>
                <button
                    className={`tab ${currentTab === 'type' ? 'selected' : ''}`}
                    onClick={() => handleTabChange('type')}>
                    Type
                </button>
            </div>
            <br/>
            {currentTab === 'name' && (
                <div>
                    <label htmlFor='nameQuery'> Enter a name to search:</label>
                    <input
                        type="text"
                        id="nameQuery"
                        name="nameQuery"
                        placeholder="Type in a name..."
                        className="date-picker"
                        value={nameQuery}
                        onChange={textEntered}
                        required
                    />
                </div>
            )}
             {currentTab === 'genre' && (
                <div>
                    <label htmlFor='genreQuery'>Select Genre:</label>
                    <select
                        id="genreQuery"
                        name="genreQuery"
                        className="date-picker"
                        value={genreQuery}
                        onChange={genreSelected}
                        required
                    >
                        <option value="" disabled>Select a genre...</option>
                        {genres.map((genre, index) => (
                            <option key={index} value={genre}>{genre}</option>
                        ))}
                    </select>
                </div>
            )}
            {currentTab === 'type' && (
                <div>
                    <label htmlFor='typeQuery'>Select Artist Type:</label>
                    <select
                        id="typeQuery"
                        name="typeQuery"
                        className="date-picker"
                        value={typeQuery}
                        onChange={typeSelected}
                        required
                    >
                        <option value="" disabled>Select a type...</option>
                        {performanceTypes.map((type, index) => (
                            <option key={index} value={type}>{type}</option>
                        ))}
                    </select>
                </div>
            )}
            {currentTab === 'date' && (
                <div>
                    <label htmlFor='type'>Select Type:</label>
                    <select value={searchType} onChange={(e) => setSearchType(e.target.value)}
                            className="date-picker" id="type" name="type">
                        <option value="performer">Performer</option>
                        <option value="vendor">Vendor</option>
                    </select>
                    <br/>
                    <label htmlFor="date">Select Date:</label>
                    <br/>
                    <DatePicker
                        selected={searchDate}
                        onChange={date => setSearchDate(date)}
                        dateFormat="yyyy-MM-dd"
                        className="date-picker"
                        id="date"
                        name="date"
                    />
                </div>
            )}
            {currentTab === 'location' && (
                <div>
                    <input type="hidden" id="latitude" value={location.lat}/>
                    <input type="hidden" id="longitude" value={location.lon}/>
                    {(!location.lat || !location.lon || locationError) && (
                        <div>
                            <label htmlFor="city">City:</label>
                            <input 
                                type="text" 
                                id="city" 
                                name="city" 
                                placeholder="Boston"
                                className="date-picker"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                            />
                            <br/>
                            <label htmlFor="stateSelect">State:</label>
                            <select 
                                id="stateSelect" 
                                name="stateSelect" 
                                className="date-picker"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                            >
                                {stateAbbreviations.map(st => (
                                    <option key={st} value={st}>{st}</option>
                                ))}
                            </select>
                        </div>
                    )}
                    {!locationError && (
                        <div style={{ textAlign: 'center' }}>
                            <a onClick={handleLocationInput}>
                                Your current location was found,<br/> click <u>here</u> to change locations.
                            </a>
                        </div>
                    )}
                    <br/>
                    <label htmlFor="distance">Search Radius:</label>
                    <select id="distance" className="date-picker">
                        <option value="5" label="5 miles"></option>
                        <option value="10" label="10 miles"></option>
                        <option value="25" label="25 miles"></option>
                        <option value="50" label="50 miles"></option>
                        <option value="100" label="100 miles"></option>
                    </select>
                </div>
            )}
            {(currentTab === "date" || currentTab === "location") && (
                <button onClick={handleSearch} className="search-button">Search</button>
            )}
            {(currentTab === "date") && results.length > 0 && (
                <div className="search-results">
                   {results.map((item, index) => (
                        <div key={index} className="search-result-item">
                            <img src={item.image_url || item.image} alt={item.name} />
                            <div className="result-details">
                                <div>{item.name}</div>
                                {item.type && <div>Type: {item.type}</div>}
                                {item.location && <div>Location: {item.location}</div>}
                                {item.address && <div>Address: {item.address}</div>}
                                {item.date && <div>Date: {item.date}</div>}
                                {item.start_time && <div>Start Time: {item.start_time}</div>}
                                {item.end_time && <div>End Time: {item.end_time}</div>}
                                {item.setting && <div>Setting: {item.setting}</div>}
                                {item.description && <div>Description: {item.description}</div>}
                                <Link to={`/view_profile/${item.profile_id || item.id}`}>View Profile</Link>
                            </div>
                        </div>
                    ))}
                </div>

            )}

            {currentTab === "location" && results.length > 0 && (
                <div className="search-results">
                    <VendorMap
                        center={[location.lat, location.lon]}
                        zoom={13}
                        scrollWheelZoom={false}
                        style={{height: "50vh"}}
                        customVenues={results}
                    />
                    {results.map((item, index) => (
                        <div key={index} className={`search-result-item ${item.has_available_dates ? 'has-available-dates' : ''}`}>
                            <img src={item.image_url || item.image} alt={item.name} />
                            <div className="result-details">
                                <div>{item.name}</div>
                                {item.type && <div>Type: {item.type}</div>}
                                {item.location && <div>Location: {item.location}</div>}
                                {item.address && <div>Address: {item.address}</div>}
                                <Link to={`/view_profile/${item.id}`}>View Profile</Link>
                            </div>
                        </div>
                    ))}
                </div>
            )}
             {(currentTab === "name" || currentTab === "genre" || currentTab === "type") && results.length > 0 && (
                <div style={{alignItems: 'center'}}>
                    {results.map((item, index) => (
                        <a key={index} href={`/view_profile/${item.id}`} style={{color:'black', textDecoration: "none"}}>
                            <div className="search-result-item">
                                <img src={item.image_url} alt="Profile Image"/>
                                <div className={"result-details"}>
                                    <h2>{item.name} - {item.type}</h2>
                                    <h4 style={{fontWeight:"normal"}}>{item.line1}</h4>
                                    <h4 style={{fontWeight:"normal"}}>{item.line2}</h4>
                                </div>
                            </div>
                        </a>
                    ))}
                    <button type='submit' onClick={nextPage} className="search-button">See more results</button>
                </div>
            )}
            {(currentTab === "name" || currentTab === "genre") && results.length === 0 && pageNumber > 5 && (
                <div style={{alignItems: 'center'}}>
                    <h3>No more profiles found</h3>
                </div>
            )}
        </div>
    );
}

export default SearchComponent;