import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useUser } from '../context/UserContext'; 
import { useNavigate } from 'react-router-dom'; 
import './UserProfile.css';
import AvailableDatesPicker from './AvailableDatesPicker';

function UserProfile() {
  const { user } = useUser();
  const [performer, setPerformer] = useState({
    name: '',
    bio: '',
    pastPerformances: [],
    reviews: [],
    availableDates: [],
    bookedDates: [],
    interestedDates: [],
    additional_images: [],
    image_url: '',
    type: '',
    setting: '',
    average_attendance: '',
    genre: '',
    location: '',
    profileId: user?.user_id || ''
  });
  const navigate = useNavigate();

  const renderImages = () => {
    if (performer.type === 'vendor') {
        if (performer.additional_images && performer.additional_images.length > 0) {
            return (
                <div className="profile-carousel">
                    <Slider {...settings}>
                        <div>
                            <img src={performer.image_url || '/default-image.png'} alt={performer.name} />
                        </div>
                        {performer.additional_images.map((imageUrl, index) => (
                            <div key={index}>
                                <img src={imageUrl} alt={`${performer.name} ${index + 1}`} />
                            </div>
                        ))}
                    </Slider>
                </div>
            );
        } 
    }
    return null;
};

  useEffect(() => {
    if (user && user.user_id) {
      const fetchProfile = async () => {
        try {
          const response = await axios.get(`https://api.live-and-local.com/profile/${user.user_id}`, { withCredentials: true });
          const data = response.data;
          setPerformer(prevPerformer => ({
            ...prevPerformer,
            ...data,
            profileId: user.user_id
          }));
          console.log('USERPROFILE response data:', data);
          console.log('Profile data test', data.availableDates);
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      };
  
      fetchProfile();
    }
  }, [user]);

  useEffect(() => {
    console.log('Updated Profile data', performer.availableDates);
  }, [performer.availableDates]); 
  
  const handleEdit = () => {
    navigate(`/edit-profile/${user.user_id}`);
  };
  
  console.log('Profile of the Profile table to UI: ', performer);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

  return (
    <><div>{performer.type === 'vendor' && (
      renderImages()
    )}</div><div className="user-profile">
        <img src={performer.image_url || 'Image'} alt={performer.name} />
        <h2>Name: {performer.name || 'Default Name'}</h2>
        <h3>{performer.type || 'Default Type'}</h3>
        <p>Bio: {performer.bio || 'Default Bio'}</p>
        {performer.type === 'vendor' ? (
          <>
            <p>Setting: {performer.setting || 'No setting specified'}</p>
            <p>Stage Types: {performer.stageTypes || 'No stage type specified'}</p>
            <p>Capacity: {performer.capacity || 'No capacity specified'}</p>
            <p>Other Notes: {performer.amenities || 'No amamenties specified'}</p>
            <p>Average Attendance: {performer.average_attendance || 'No average attendance available'}</p>
          </>
        ) : (
          <><p>Genre: {performer.genre || 'No genre specified'}</p><p>Location: {performer.location || 'No location available'}</p><p>Performance Type: {performer.performance_type || 'No type available'}</p><p>Experience: {performer.experience_level || 'No Experience available'}</p><p>Preferred Venues Types: {performer.preferred_venues || 'No preferred_venues available'}</p></>
        )}
        <button className="edit-button" onClick={handleEdit}>Edit Profile</button>
        <p></p>
        <AvailableDatesPicker
          availableDates={performer.availableDates || []}
          bookedDates={performer.bookedDates || []}
          interestedDates={performer.interestedDates || []}
          setAvailableDates={(dates) => setPerformer({ ...performer, availableDates: dates })}
          profileId={performer.profileId}
          isEditable={true}
          userType={performer.type}
          isViewProfile={false} />
      </div></>
  );
}

export default UserProfile;