import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, IconButton, Chip, Menu, MenuItem } from '@mui/material';
import { Search, FilterList, MoreVert, Description } from '@mui/icons-material';
import './BookingStylesImp.css';
import ContractDashboard from './ImpContractDashboard';

const ContractsPage = ({ profileId }) => {
  const [contracts, setContracts] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [showContractForm, setShowContractForm] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Status filter menu state
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleStatusClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleStatusClose = (status) => {
    if (status && status !== statusFilter) {
      setStatusFilter(status);
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchContracts = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`https://api.live-and-local.com/get-contracts-byvenue/${profileId}`);
        setContracts(response.data);
        setFilteredContracts(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching contracts:', error);
        setError(error);
        setIsLoading(false);
      }
    };

    if (profileId) {
      fetchContracts();
    }
  }, [profileId]);

  // Filter contracts whenever search term or status filter changes
  useEffect(() => {
    const filterContracts = () => {
      let results = contracts;

      // Apply search filter
      if (searchTerm) {
        results = results.filter(contract => 
          contract.other_party.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          contract.booking_details.genres.some(genre => 
            genre.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      }

      // Apply status filter
      if (statusFilter !== 'All') {
        results = results.filter(contract => 
          contract.status === statusFilter
        );
      }

      setFilteredContracts(results);
    };

    filterContracts();
  }, [searchTerm, statusFilter, contracts]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleContractSubmit = (contractData) => {
    console.log('New contract submitted:', contractData);
    setShowContractForm(false);
  };

  const handleContractClick = (contract) => {
    setSelectedContract(contract);
  };

  const handleBackToContracts = () => {
    setSelectedContract(null);
  };

  if (selectedContract) {
    return <ContractDashboard contract={selectedContract} onBack={handleBackToContracts} page={'Contracts'}/>;
  }

  return (
    <Box className="venue-booking-container" sx={{ display: 'flex' }}>
      <Box className="venue-main-content">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4">Contracts</Typography>
          <Button 
            className="custom-button"
            onClick={() => setShowContractForm(!showContractForm)}
          >
            {showContractForm ? 'Cancel' : 'Create New Contract'}
          </Button>
        </Box>

        {showContractForm ? (
          <Box className="venue-booking-form">
            <Typography variant="h6" sx={{ mb: 2 }}>New Contract</Typography>
            <TextField fullWidth label="Artist Name" variant="outlined" sx={{ mb: 2 }} />
            <TextField fullWidth label="Event Name" variant="outlined" sx={{ mb: 2 }} />
            <TextField fullWidth label="Date" type="date" InputLabelProps={{ shrink: true }} variant="outlined" sx={{ mb: 2 }} />
            <TextField fullWidth label="Terms and Conditions" multiline rows={4} variant="outlined" sx={{ mb: 2 }} />
            <Button className="custom-button" onClick={() => handleContractSubmit({})}>
              Create Contract
            </Button>
          </Box>
        ) : (
          <Box className="booking-list">
            <Typography variant="h6" sx={{ mb: 2 }}>Current Contracts</Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <TextField 
                placeholder="Search contracts..." 
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <Search />,
                }}
              />
              <Box>
                <Button 
                  variant="outlined" 
                  sx={{ mr: 1 }}
                  onClick={handleStatusClick}
                  endIcon={<FilterList />}
                >
                  {statusFilter}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => handleStatusClose()}
                >
                  {['All', 'Booked', 'Pending', 'Cancelled'].map((status) => (
                    <MenuItem 
                      key={status}
                      onClick={() => handleStatusClose(status)}
                      selected={status === statusFilter}
                    >
                      {status}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>

            <List>
              {filteredContracts.map((contract) => (
                <ListItem 
                  key={contract.id} 
                  className="booking-list-item"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '16px',
                    margin: '8px 0',
                    backgroundColor: '#fff',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleContractClick(contract)}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: '#EF6191' }}><Description /></Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                      primary={<Typography variant="h6">{contract.other_party.name}</Typography>}
                      secondary={
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
                          <Typography variant="body2" color="text.secondary">
                            {contract.booking_details.genres.join(', ')} • {contract.date} • ${contract.bid_amount} 
                          </Typography>
                          <Chip 
                            label={contract.status} 
                            size="small" 
                            sx={{ 
                              mt: 1, 
                              bgcolor: contract.status === 'Booked' ? '#e8f5e9' : '#fff3e0', 
                              color: contract.status === 'Booked' ? '#2e7d32' : '#ed6c02',
                              maxWidth: 'fit-content'
                            }} 
                          />
                        </Box>
                      }
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Button 
                      variant="contained" 
                      sx={{ 
                        bgcolor: '#EF6191', 
                        '&:hover': { bgcolor: '#c45577' } 
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleContractClick(contract);
                      }}
                    >
                      View Contract
                    </Button>
                    <IconButton onClick={(e) => e.stopPropagation()}>
                      <MoreVert />
                    </IconButton>
                  </Box>
                </ListItem>
              ))}
            </List>

            <Button className="custom-button" fullWidth sx={{ mt: 2 }}>
              Load more
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ContractsPage;