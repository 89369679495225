import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './AuthStyles.css';

function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { token } = useParams();
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            alert("Passwords do not match!");
            return;
        }

        try {
            const response = await axios.post('https://api.live-and-local.com/reset-password', {
                token: token,
                password: password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.success) {
                alert('Password has been reset successfully.');
                navigate('/login');
            } else {
                alert('Error: ' + response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : "No response from server");
            alert('An error occurred while resetting your password.');
        }
    };

    return (
        <div className="wrapper">
            <form onSubmit={handleSubmit}>
                <h1>Set New Password</h1>
                <div className="input-box">
                    <input 
                        type="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        placeholder="New Password" 
                        required
                    />
                    <i className="bx bxs-lock-alt"></i>
                </div>
                <div className="input-box">
                    <input 
                        type="password" 
                        value={confirmPassword} 
                        onChange={(e) => setConfirmPassword(e.target.value)} 
                        placeholder="Confirm New Password" 
                        required
                    />
                    <i className="bx bxs-lock-alt"></i>
                </div>
                <button type="submit" className="btn">Reset Password</button>
            </form>
        </div>
    );
}

export default ResetPassword;