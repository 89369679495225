import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ArtistBookingDiscover.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArtistBookingBidPopup from './ArtistBookingBidPopup';
const Feed = () => {
    const [location, setLocation] = useState(null);
    const [cityState, setCityState] = useState('');
    const [musicType, setMusicType] = useState('Rock');
    const [feedItems, setFeedItems] = useState([]);
    const [distance, setDistance] = useState(50);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [showContractPopup, setShowContractPopup] = useState(false);
    const genres = [
        'Rock', 'Pop', 'Jazz', 'Blues', 'Hip-Hop', 'Country', 'EDM (Electronic Dance Music)', 'Folk',
        'Reggae', 'Classical', 'Indie', 'R&B/Soul', 'Metal', 'World Music', 'Other'
    ];
    const distances = {
        10 : "Ten Miles",
        25 : "Twenty-Five Miles",
        50 : "Fifty Miles",
        100 : "One-Hundred Miles"
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const handleUseLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const { latitude, longitude } = position.coords;
                setLocation({ latitude, longitude });
            });
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };
    const handleSearch = async () => {
        try {
            const response = await axios.post('https://api.live-and-local.com/LocalAvailableBookings', {
                location,
                cityState,
                distance,
                musicType
            }, { withCredentials: true });
            console.log(response);
            setFeedItems(response.data);
            console.log(feedItems)
        } catch (error) {
            console.error('Error fetching feed data:', error);
        }
    };
    const handleBidClick = (item) => {
        setSelectedBooking(item)
        setShowContractPopup(true);
    };
    const handleCloseContractPopup = () => {
        setShowContractPopup(false);
    };
    return (
        <div className="feed-container">
            <div className="subbar">
                {!location && (
                    <>
                        <button onClick={handleUseLocation} style={{ fontSize: '12px' }}>Current Location</button>
                        <input
                            type="text"
                            placeholder="Enter City, State"
                            value={cityState}
                            onChange={(e) => setCityState(e.target.value)}
                        />
                    </>
                )}
                <select value={distance} onChange={(e) => setDistance(e.target.value)}>
                    {Object.entries(distances).map(([value, label]) => (
                        <option key={value} value={Number(value)}>
                            {label}
                        </option>
                    ))}
                </select>
                <select value={musicType} onChange={(e) => setMusicType(e.target.value)}>
                    {genres.map((genre, index) => (
                        <option key={index} value={genre.toLowerCase()}>
                            {genre}
                        </option>
                    ))}
                </select>
                <button onClick={handleSearch}>Search</button>
            </div>
            <br/>
            <div className="feed">
                {feedItems && feedItems.length > 0 ? (
                    feedItems.map((item, index) => (
                        <div className="event-card" key={index}>
                            <div className="profile-picture">
                                <img src={item.artist_url} alt={`${item.artist_name} Profile`}/>
                            </div>
                            <div className="artist-info">
                                <h4>{item.venue_name}</h4>
                            </div>
                            {item.images && item.images.length > 0 && (
                                <div className="profile-carousel">
                                    <Slider {...settings}>
                                        {item.images.map((image, imgIndex) => (
                                            <div key={imgIndex}>
                                                <img src={image} alt={`Event image ${imgIndex + 1}`}/>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            )}
                            <div className="event-details">
                                <p>
                                    <strong>Minimum Price:</strong> {item.min_cost}
                                </p>
                                <p>
                                    <strong>Date:</strong> {new Date(item.date).toDateString()}
                                </p>
                                <p>
                                    <strong>Start Time:</strong> {item.start_time} &nbsp; <strong>End Time:</strong> {item.end_time}
                                </p>
                                <p>
                                    <strong>Location:</strong> {item.location}
                                </p>
                            </div>
                            <div className="buy-tickets">
                                <button
                                    onClick={() => handleBidClick(item)}
                                >
                                    View Event
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="no-results">No results found.</p>
                )}
                {showContractPopup && (
                    <ArtistBookingBidPopup
                        booking={selectedBooking}
                        onClose={handleCloseContractPopup}
                    />
                )}
            </div>
        </div>
    );
};
export default Feed;