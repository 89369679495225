import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  InputAdornment,
  Snackbar,
  Alert,
} from '@mui/material';
import { 
  Event, 
  AttachMoney, 
  Schedule,
  Place,
  CheckCircle,
  Edit as EditIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

const ArtistContractDashboard = ({ contract, onBack, page }) => {
  // All hooks at the top level
  const [openSignedContract, setOpenSignedContract] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [editForm, setEditForm] = useState({
    bid_amount: '',
    artist_notes: '',
    technical_requirements: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!contract) {
    return <div>Loading...</div>;
  }
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccessSnackbar(false);
  };

  const handleOpenEdit = () => {
    setEditForm({
      bid_amount: contract.bid_amount || '',
      artist_notes: contract.artist_notes || '',
      technical_requirements: contract.technical_requirements || '',
    });
    setOpenEditDialog(true);
  };

  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  const handleEditChange = (field) => (event) => {
    setEditForm(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleSubmitEdit = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch('https://api.live-and-local.com/api/update-contract', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          contractId: contract.contract_id,
          bidAmount: editForm.bid_amount,
          technicalRequirements: editForm.technical_requirements,
          artistNotes: editForm.artist_notes,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update contract');
      }

      const data = await response.json();
      console.log('Contract updated successfully:', data);
      handleCloseEdit();
      setOpenSuccessSnackbar(true); // Show success notification

    } catch (error) {
      console.error('Error updating contract:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleViewSignedCopy = () => {
    setOpenSignedContract(true);
  };

  const handleCloseSignedCopy = () => {
    setOpenSignedContract(false);
  };

  const contractDetails = {
    id: contract.id,
    artist: contract.artist,
    event: contract.event,
    date: contract.date,
    status: contract.status,
    venue: contract.name,
    fee: contract.bid_amount,
    duration: "2 hours",
    equipment: contract.technical_requirements,
    specialRequirements: contract.artist_notes,
    contractTerms: [
      "Artist will perform a 90-minute set",
      "Sound check at 6 PM",
      "Payment to be made immediately after the performance",
    ],
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'signed':
      case 'booked':
        return 'success';
      case 'pending':
        return 'warning';
      default:
        return 'default';
    }
  };

  const isContractConfirmed = contractDetails.status.toLowerCase() === 'signed' || 
                             contractDetails.status.toLowerCase() === 'booked';

  return(
    <Box sx={{ p: 3 }}>
      <Button onClick={onBack} variant="outlined" sx={{ mb: 2 }}>
        Back to {page}
      </Button>
      
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={8}>
            <Typography variant="h4" gutterBottom>{contractDetails.venue}</Typography>
            <Typography variant="h6" color="text.secondary">{contract.location}</Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
            <Chip 
              label={contractDetails.status} 
              color={getStatusColor(contractDetails.status)}
              sx={{ fontSize: '1rem', p: 1 }}
            />
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>Event Details</Typography>
            <List>
              <ListItem>
                <Event sx={{ mr: 2 }} />
                <ListItemText primary="Date" secondary={contractDetails.date} />
              </ListItem>
              <ListItem>
                <Schedule sx={{ mr: 2 }} />
                <ListItemText primary="Duration" secondary={contractDetails.duration} />
              </ListItem>
              <ListItem>
                <Place sx={{ mr: 2 }} />
                <ListItemText primary="Venue" secondary={contractDetails.venue} />
              </ListItem>
              <ListItem>
                <AttachMoney sx={{ mr: 2 }} />
                <ListItemText primary="Fee" secondary={contractDetails.fee} />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>Performance Requirements</Typography>
            <List>
              <ListItem>
                <ListItemText primary="Equipment" secondary={contractDetails.equipment} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Special Requirements" secondary={contractDetails.specialRequirements} />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Contract Terms</Typography>
            <List>
              {contractDetails.contractTerms.map((term, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemText primary={term} />
                  </ListItem>
                  {index < contractDetails.contractTerms.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>

      <Box sx={{ mt: 3, display: 'flex', justifyContent: isContractConfirmed ? 'flex-end' : 'space-between' }}>
        {!isContractConfirmed && (
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleOpenEdit}
          >
            Edit Contract
          </Button>
        )}
        {isContractConfirmed && (
          <Button 
            variant="contained" 
            color="secondary"
            onClick={handleViewSignedCopy}
          >
            View Signed Copy
          </Button>
        )}
      </Box>

      {/* Edit Dialog */}
      <Dialog
        open={openEditDialog}
        onClose={handleCloseEdit}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Edit Contract Details
          <IconButton
            aria-label="close"
            onClick={handleCloseEdit}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Bid Amount"
              type="number"
              value={editForm.bid_amount}
              onChange={handleEditChange('bid_amount')}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                endAdornment: (
                  <InputAdornment position="end">
                    <EditIcon color="action" />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 3 }}
            />
            <TextField
              fullWidth
              label="Technical Requirements"
              multiline
              rows={4}
              value={editForm.technical_requirements}
              onChange={handleEditChange('technical_requirements')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EditIcon color="action" />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 3 }}
            />
            <TextField
              fullWidth
              label="Artist Notes"
              multiline
              rows={4}
              value={editForm.artist_notes}
              onChange={handleEditChange('artist_notes')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EditIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit}>Cancel</Button>
          <Button 
            onClick={handleSubmitEdit} 
            variant="contained" 
            color="primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Saving...' : 'Save Changes'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSignedContract}
        onClose={handleCloseSignedCopy}
        fullWidth
        maxWidth="md"
        aria-labelledby="signed-contract-dialog-title"
      >
        <DialogTitle id="signed-contract-dialog-title">
          Signed Contract
          <IconButton
            aria-label="close"
            onClick={handleCloseSignedCopy}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            {/* You can add a close icon here if desired */}
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                Artist: {contract.artistName}
                <CheckCircle color="success" sx={{ ml: 1 }} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                Venue: {contractDetails.venue}
                <CheckCircle color="success" sx={{ ml: 1 }} />
              </Typography>
            </Grid>
          </Grid>

          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>Event Details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>Date: {contractDetails.date}</Typography>
                <Typography>Duration: {contractDetails.duration}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Location: {contract.location}</Typography>
                <Typography>Fee: {contractDetails.fee}</Typography>
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>Performance Requirements</Typography>
            <Typography>Equipment: {contractDetails.equipment}</Typography>
            <Typography>Special Requirements: {contractDetails.specialRequirements}</Typography>
          </Paper>

          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Contract Terms</Typography>
            <List>
              {contractDetails.contractTerms.map((term, index) => (
                <ListItem key={index}>
                  <ListItemText primary={term} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSignedCopy} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Contract updated successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ArtistContractDashboard;