import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './BookingCardInfo.css';
import { FaCalendarAlt, FaMapMarkerAlt, FaMusic, FaMoneyBillWave, FaUser } from 'react-icons/fa';

function ResCardInfo({ userId, userType }) {
  const [datesInfo, setDatesInfo] = useState([]);
  const [statusType, setStatusType] = useState('booked');  // Default to showing available dates
  const [message, setMessage] = useState('');

  return (
    <div className="booking-page">
      <div className="nav-bar">
        <button className={`nav-button ${statusType === 'booked' ? 'active' : ''}`} onClick={() => setStatusType('booked')}>Booked</button>
        <button className={`nav-button ${statusType === 'interest' ? 'active' : ''}`} onClick={() => setStatusType('interest')}>Interest Shown</button>
      </div>
      <div className="content">
        {message && <p>{message}</p>}
        {datesInfo.length > 0 ? (
          datesInfo.map((info, index) => (
            <div key={index} className="date-info">
              <p><FaCalendarAlt /> Date: {info.date}</p>
              <p><FaMapMarkerAlt /> Location: {info.booking_location}</p>
              <p><FaMusic /> Description: {info.description}</p>
              <p><FaMoneyBillWave /> Cost: ${info.cost}</p>
              {userType === 'performer' && (
                <div>
                  <p>Venue Type: {info.venue_type}</p>
                  <p>Gig Amount: {info.gig_amount}</p>
                </div>
              )}
              {userType === 'vendor' && (
                <div>
                  <p>Song Samples: {info.song_samples}</p>
                  <p>Genre: {info.genre}</p>
                  <p>Performer Type: {info.performer_type}</p>
                </div>
              )}
              {statusType === 'booked' && info.booked_by && (
                <div className="user-info">
                  <p><FaUser /> Booked By: {info.booked_by.username}</p>
                  <p>Email: {info.booked_by.email}</p>
                  <p>Name: {info.booked_by.name}</p>
                  <p>Location: {info.booked_by.location}</p>
                  <p>Bio: {info.booked_by.bio}</p>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}

export default ResCardInfo;