import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, TextField, Button, List, ListItem, 
  ListItemText, Avatar, IconButton, Chip, Grid, Card, 
  CardContent, CardActions, Divider, Paper, InputAdornment
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { 
  Search, FilterList, MoreVert, Event, AccessTime, Place, Person
} from '@mui/icons-material';

const ActiveBiddingsPage = ({ setSelectedBooking, setCurrentPage, profileId }) => {
  const [expandedBooking, setExpandedBooking] = useState(null);
  const [activeBiddings, setActiveBiddings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchActiveBiddings = async () => {
      try {
        const response = await fetch(`https://api.live-and-local.com/get-active-biddings/${profileId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch active biddings');
        }
        const data = await response.json();
        setActiveBiddings(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching active biddings:', error);
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchActiveBiddings();
  }, [profileId]);

  const handleExpandBooking = (bookingId) => {
    setExpandedBooking(expandedBooking === bookingId ? null : bookingId);
  };

  const handleSelectBooking = (booking) => {
    setSelectedBooking(booking);
    setCurrentPage('viewSingleBooking');
  };


  const handleViewArtistProfile = (artistId) => {
    navigate(`/view_profile/${artistId}`); // Use navigate to go to the artist profile page
  };

  const renderBookingRow = (booking) => (
    <Paper 
      elevation={2} 
      sx={{ mb: 3, overflow: 'hidden', borderRadius: '8px', cursor: 'pointer' }}
      onClick={() => {
        setSelectedBooking(booking);
        setCurrentPage('viewSingleBooking');
      }}
    >
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={2}>
            <Typography variant="body1" fontWeight="bold">
              {new Date(booking.date).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' })}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">
              {`${booking.startTime} - ${booking.endTime}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">{booking.details.performanceType || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2">{booking.details.genres.join(', ') || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">
              {booking.details.priceRange.min && booking.details.priceRange.max
                ? `$${booking.details.priceRange.min} - $${booking.details.priceRange.max}`
                : 'Price not set'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Chip 
              label={booking.status} 
              color="secondary" 
              size="small" 
              sx={{ 
                backgroundColor: '#9c27b0',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '0.75rem',
              }} 
            />
          </Grid>
        </Grid>
      </Box>
      
      <Box sx={{ p: 2, backgroundColor: '#f9f9f9' }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>Interested Artists</Typography>
        <List>
          {booking.interestedArtists && booking.interestedArtists.length > 0 ? (
            booking.interestedArtists.map((artist) => (
              <ListItem key={artist.id} sx={{ py: 2, flexDirection: 'column', alignItems: 'stretch' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{artist.name}</Typography>
                  <Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ mr: 1 }}
                      onClick={() => handleViewArtistProfile(artist.id)} // Add onClick here
                    >
                      VIEW PROFILE
                    </Button>
                    <Button variant="contained" sx={{ backgroundColor: '#9c27b0', color: 'white', '&:hover': { backgroundColor: '#7b1fa2' } }}>
                      ACCEPT BID
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2" color="text.secondary">
                    Bid: {artist.bid ? `$${artist.bid}` : 'Not specified'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Rating: {artist.rating ? `${artist.rating}/5` : 'Not rated'}
                  </Typography>
                </Box>
              </ListItem>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">No interested artists yet.</Typography>
          )}
        </List>
      </Box>
    </Paper>
  );
  return (
    <Box sx={{ p: 3, maxWidth: '1200px', margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>Active Biddings</Typography>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <TextField 
          placeholder="Search bookings..." 
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          sx={{ 
            width: '60%',
            '& .MuiOutlinedInput-root': {
              borderRadius: '20px',
            }
          }}
        />
        <Box>
          <Button 
            variant="outlined" 
            sx={{ 
              mr: 1, 
              borderRadius: '20px', 
              textTransform: 'uppercase',
              fontWeight: 'bold',
            }}
          >
            Filter
          </Button>
          <IconButton sx={{ border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '50%' }}>
            <FilterList />
          </IconButton>
        </Box>
      </Box>

      {isLoading ? (
        <Typography>Loading active biddings...</Typography>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <List>
          {activeBiddings.map(renderBookingRow)}
        </List>
      )}
    </Box>
  );
};

export default ActiveBiddingsPage;