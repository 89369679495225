import React from 'react';
import { Link } from 'react-router-dom'
import './Card.css'; 

function Card({ item }) {
    console.log('Item: ', item);
    return (
        <div className="card-container">
            <Link to={`/view_profile/${item.id}`}>
                <img src={item.image} alt={item.name} className="card-image" />
                <div className="card-content">
                    <h3 className="card-title">{item.name}</h3>
                    {item.genre && <p className="card-text">Genre: {item.genre}</p>}
                    {item.location && <p className="card-text">Location: {item.location}</p>}
                </div>
            </Link>
        </div>
    );
}

export default Card;