import React, { useRef, useState, useEffect } from 'react';
import Card from './Card';
import './CategoryPreview.css';

function CategoryPreview({ title, items }) {
    const scrollContainer = useRef(null);

    const handleWheel = (e) => {
        if (scrollContainer.current) {
            scrollContainer.current.scrollUp += e.deltaY + e.deltaX; // Adjust according to your needs
        }
    };

    return (
        <div>
             <h2 className="title">{title}</h2> 
            <div className="category-preview" ref={scrollContainer} onWheel={handleWheel}>
                {items.map(item => (
                    <Card key={item.id} item={item} />
                ))}
            </div>
        </div>
    );
}

export default CategoryPreview;