import React from 'react';

function BookingCalendar({ availability, onBook }) {
  return (
    <div>
      <h2>Booking Calendar</h2>
      <ul>
        {availability.map((date, index) => (
          <li key={index}>
            {date.day}: {date.available ? 'Available' : 'Booked'}
            {date.available && <button onClick={() => onBook(date.day)}>Book</button>}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default BookingCalendar;