import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    Grid,
    Paper,
    List,
    ListItem,
    ListItemText,
    Divider,
    Chip,
    Box,
    Snackbar,
    Alert
} from '@mui/material';
import {
    Event,
    Schedule,
    Place,
    AttachMoney,
} from '@mui/icons-material';

const ArtistBookingBidPopup = ({ booking, onClose }) => {
    const [bidAmount, setBidAmount] = useState('');
    const [technicalRequirements, setTechnicalRequirements] = useState('');
    const [artistNotes, setArtistNotes] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    console.log(booking)
    const handleBidSubmit = async () => {
        const requestData = {  // Changed from bidData to requestData
            bookingId: booking.booking_id,
            bidAmount: bidAmount,
            artistNotes: artistNotes,
            technicalRequirements: technicalRequirements,
            venue_id: booking.venue_user_id,
            eventDetails: {
                venueName: booking.venue_name,
                date: booking.date,
                startTime: booking.start_time,
                endTime: booking.end_time,
                location: booking.location
            }
        };
        
        try {
            // Submit the bid
            const bidResponse = await fetch('https://api.live-and-local.com/api/submit-bid', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),  // Changed from bidData to requestData
                credentials: 'include',
            });
            
            if (!bidResponse.ok) {
                throw new Error('Failed to submit bid');
            }
    
            const responseData = await bidResponse.json();  // Changed from bidData to responseData
            console.log('WHAT THE FUCKSSSSSSSSSS',requestData);
            // Send notification message to venue
            
    
            setOpenSnackbar(true);
            setTimeout(() => {
                onClose();
            }, 2000);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };


    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'pending':
                return 'warning';
            default:
                return 'default';
        }
    };
    return (
        <><Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                <Button onClick={onClose} variant="outlined" sx={{ mb: 2 }}>
                    Back to Contracts
                </Button>
            </DialogTitle>
            <DialogContent dividers>
                <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={8}>
                            <Typography variant="h4" gutterBottom>{booking.venue_name}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
                            <Chip
                                label="Available"
                                color={getStatusColor('Available')}
                                sx={{ fontSize: '1rem', p: 1 }} />
                        </Grid>
                    </Grid>
                </Paper>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
                            <Typography variant="h6" gutterBottom>Event Details</Typography>
                            <List>
                                <ListItem>
                                    <Event sx={{ mr: 2 }} />
                                    <ListItemText primary="Date" secondary={new Date(booking.date).toLocaleDateString('en-US',
                                        { year: 'numeric', month: 'long', day: 'numeric' })} />
                                </ListItem>
                                <ListItem>
                                    <Schedule sx={{ mr: 2 }} />
                                    <ListItemText primary="Time" secondary={`${booking.start_time}-${booking.end_time}`} />
                                </ListItem>
                                <ListItem>
                                    <Place sx={{ mr: 2 }} />
                                    <ListItemText primary="Venue" secondary={booking.location} />
                                </ListItem>
                                <ListItem>
                                    <AttachMoney sx={{ mr: 2 }} />
                                    <ListItemText primary="Minimum Price" secondary={`$${booking.min_cost}`} />
                                </ListItem>
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
                            <Typography variant="h6" gutterBottom>Performance Requirements</Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Venue Type" secondary={booking.venue_type.replace(/[\[\]"]/g, '').split(",").join(", ")} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Experience Level" secondary={booking.experience_level} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Genres Preferred" secondary={booking.genre} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Capacity" secondary={booking.capacity} />
                                </ListItem>
                            </List>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={2} sx={{ p: 2 }}>
                            <Typography variant="h6" gutterBottom>Your Proposal</Typography>
                            <TextField
                                label="Your Bid Amount"
                                type="number"
                                fullWidth
                                value={bidAmount}
                                onChange={(e) => setBidAmount(e.target.value)}
                                InputProps={{ startAdornment: '$' }}
                                sx={{ mb: 2 }} />
                            <TextField
                                label="Technical Requirements"
                                multiline
                                rows={3}
                                fullWidth
                                value={technicalRequirements}
                                onChange={(e) => setTechnicalRequirements(e.target.value)}
                                sx={{ mb: 2 }} />
                            <TextField
                                label="Additional Notes"
                                multiline
                                rows={4}
                                fullWidth
                                value={artistNotes}
                                onChange={(e) => setArtistNotes(e.target.value)} />
                        </Paper>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', p: 2 }}>
                    <Button variant="contained" color="primary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleBidSubmit} disabled={!bidAmount}>
                        Submit Proposal
                    </Button>
                </Box>
            </DialogActions>
        </Dialog><Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Your bid has been successfully submitted!
                </Alert>
            </Snackbar></>
        
    );
};
export default ArtistBookingBidPopup;