import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import './UserProfile.css';
import './userbooking.css';
import BookingCardInfo from './BookingInfoCard';
import AvailableDatesPicker from './AvailableDatesPicker';
import StatusNavBar from './StatusNavBar';

function UserBooking() {
  const { user } = useUser(); // Assuming `user` contains `user_id` when logged in
  const navigate = useNavigate();
  const [performer, setPerformer] = useState({
    name: '',
    bio: '',
    pastPerformances: [],
    reviews: [],
    availableDates: [],
    type: '',
  });

  useEffect(() => {
    const fetchProfile = async () => {
      if (user && user.user_id) {
        try {
          const response = await axios.get(`https://api.live-and-local.com/profile/${user.user_id}`, { withCredentials: true });
          // Update state with fetched data, ensuring we handle any missing `availableDates` gracefully
          console.log('The Current User', user);
          console.log('The Current UserID', user.user_id);
          setPerformer({ ...response.data, availableDates: response.data.availableDates || [], profileId: user.user_id, type: response.data.type }); // Ensure profileId is set correctly
          console.log('Fetched Profile Data:', response.data);
          console.log('Performer Type:', response.data.type);
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      }
    };

    fetchProfile();
  }, [user]); // Dependency array includes `user` and `currentView`

  const handleEdit = () => {
    navigate(`/edit-profile/${user.user_id}`)
  };

  return (
    <div className="user-booking">
    <AvailableDatesPicker
      availableDates={performer.availableDates || []}
      bookedDates={performer.bookedDates || []}
      setAvailableDates={(dates) => setPerformer({ ...performer, availableDates: dates })}
      profileId={performer.profileId}
      isEditable={true}
      userType={performer.type}
      isViewProfile={false}
    />
     <div className="date-info-cards">
          <BookingCardInfo userId={user.user_id} userType={performer.type}/>
      </div>
    </div>
  );
}

export default UserBooking;