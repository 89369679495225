import React, { createContext, useState, useEffect } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [performers, setPerformers] = useState([]);
    const [venues, setVenues] = useState([]);

    const hardcodedPerformers = [
        { 
            name: "DJ Mike", 
            genre: "Electronic", 
            id: 1, 
            image: "https://t4.ftcdn.net/jpg/05/65/32/47/360_F_565324708_RYdtKOxeVUJKBuJDeqBqJ5hxxg8xXUvY.jpg"
        }, 
        { 
            name: "Jazz Trio", 
            genre: "Jazz", 
            id: 2, 
            image: "https://www.lastminutemusicians.com/how_to_get_gigs/wp-content/uploads/2013/10/Jazz-trio-e1520338456404.jpg"
        },
        { 
            name: "DJ Yella", 
            genre: "DJ", 
            id: 3, 
            image: "https://img.thedailybeast.com/image/upload/c_crop,d_placeholder_euli9k,h_1439,w_2560,x_0,y_0/dpr_1.5/c_limit,w_1044/fl_lossy,q_auto/v1492177682/articles/2015/08/11/the-n-w-a-member-turned-pornographer/150810-yamato-djyella-tease_q5qd3f"
        }, 
        { 
            name: "Lil Baby", 
            genre: "Rap", 
            id: 4, 
            image: "https://i.scdn.co/image/ab6761610000e5eb6cad3eff5adc29e20f189a6c"
        },
        { 
            name: "King Von", 
            genre: "Rap", 
            id: 5, 
            image: "https://www.passionweiss.com/wp-content/uploads/2019/09/king-von.jpg"
        }, 
        { 
            name: "G Money Millionaire", 
            genre: "Rap", 
            id: 6, 
            image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnzo3hrmFes-liUGqeXDV3X9TTVcGtKkA3mXWBiHtlCQ&s"
        }, 
        { 
            name: "Rawley", 
            genre: "Chill", 
            id: 7, 
            image: "https://akns-images.eonline.com/eol_images/Entire_Site/2023031/rs_1024x759-230131124719-1024-joey-fatone-mv-13123.png?fit=around%7C776:576&output-quality=90&crop=776:576;center,top"
        }, 
        { 
            name: "Lil Baby", 
            genre: "Rap", 
            id: 4, 
            image: "https://i.scdn.co/image/ab6761610000e5eb6cad3eff5adc29e20f189a6c"
        },
        { 
            name: "King Von", 
            genre: "Rap", 
            id: 5, 
            image: "https://www.passionweiss.com/wp-content/uploads/2019/09/king-von.jpg"
        }, 
        { 
            name: "G Money Millionaire", 
            genre: "Rap", 
            id: 6, 
            image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnzo3hrmFes-liUGqeXDV3X9TTVcGtKkA3mXWBiHtlCQ&s"
        }, 
        { 
            name: "Rawley", 
            genre: "Chill", 
            id: 7, 
            image: "https://akns-images.eonline.com/eol_images/Entire_Site/2023031/rs_1024x759-230131124719-1024-joey-fatone-mv-13123.png?fit=around%7C776:576&output-quality=90&crop=776:576;center,top"
        }
        // your performers data
    ];
    const hardcodedVenues = [
        { 
            name: "The Jazz Club", 
            location: "Downtown", 
            id: 1, 
            image: "https://assets.vogue.com/photos/63091f211c979041e1d8d0f3/master/w_2560%2Cc_limit/Aman%2520New%2520York%2C%2520USA%2520-%2520The%2520Jazz%2520Club_37358.jpg"
        }, 
        { 
            name: "Rock Bar", 
            location: "Uptown", 
            id: 2, 
            image: "https://images.rosewoodhotels.com/is/image/rwhg/Carlyle_Bemelmans_Durston_Saylor"
        }, 
        { 
            name: "Sharivari", 
            location: "Rome", 
            id: 2, 
            image: "https://www.sharivari.it/wp-content/uploads/2022/09/271142207_4941182065940391_6519532511985100212_n.jpg"
        }, 
        { 
            name: "Ruin Bar", 
            location: "Budapest Disctict I", 
            id: 2, 
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Mazel_Tov_Ruin_Bar_%2842253982234%29.jpg/1200px-Mazel_Tov_Ruin_Bar_%2842253982234%29.jpg"
        }, 
        { 
            name: "Morisons Bar", 
            location: "Disctrict II", 
            id: 2, 
            image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTi_GtTDeb2pnc5I41x1PjB0nNoEAqX8JczJXCa176Ww&s"
        }, 
        { 
            name: "Merriweather Post", 
            location: "Upper Marboro", 
            id: 2, 
            image: "https://assets.simpleviewinc.com/simpleview/image/upload/c_fill,h_650,q_75,w_1440/v1/clients/howardcounty/Florence_The_Machine_at_Merriweather_Post_Pavilion_Drone_Photo_by_Will_Cocks_9bc798e8-d62a-4547-85d1-3174a5e46c67.jpg"
        }, 
        { 
            name: "Big Night Live", 
            location: "Boston", 
            id: 2, 
            image: "https://images.squarespace-cdn.com/content/v1/5d656dbcc5343d0001824fb0/1572358700759-31P5I7W8CJJAJQ6ISSH2/N6gpz4hq.jpeg"
        }, 
        { 
            name: "Rock Bar", 
            location: "Uptown", 
            id: 2, 
            image: "https://images.rosewoodhotels.com/is/image/rwhg/Carlyle_Bemelmans_Durston_Saylor"
        }
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const performersResponse = await fetch('https://api.live-and-local.com/api/performers');
                const vendorsResponse = await fetch('https://api.live-and-local.com/api/vendors');
                const performersData = await performersResponse.json();
                const venuesData = await vendorsResponse.json();

                // Combine backend data with hardcoded data if needed
                const combinedPerformers = performersData.length < hardcodedPerformers.length
                    ? [...performersData, ...hardcodedPerformers.slice(performersData.length)]
                    : performersData;
                
                const combinedVenues = venuesData.length < hardcodedVenues.length
                    ? [...venuesData, ...hardcodedVenues.slice(venuesData.length)]
                    : venuesData;

                setPerformers(combinedPerformers);
                setVenues(combinedVenues);
            } catch (error) {
                console.error('Error fetching data:', error);
                // Fallback to hardcoded data in case of error
                setPerformers(hardcodedPerformers);
                setVenues(hardcodedVenues);
            }
        };

        fetchData();
    }, []);


    return (
        <DataContext.Provider value={{ performers, setPerformers, venues, setVenues }}>
            {children}
        </DataContext.Provider>
    );
};