import React, { useState } from 'react';
import axios from 'axios';
import './AuthStyles.css'; // Reuse your existing styles

function ForgotPassword() {
    const [email, setEmail] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://api.live-and-local.com/forgot-password', {
                email: email
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.success) {
                alert('Password reset link has been sent to your email.');
            } else {
                alert('Error: ' + response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : "No response from server");
            alert('An error occurred while requesting password reset.');
        }
    };

    return (
        <div className="wrapper">
            <form onSubmit={handleSubmit}>
                <h1>Reset Password</h1>
                <div className="input-box">
                    <input 
                        type="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        placeholder="Enter your email" 
                        required
                    />
                    <i className="bx bxs-envelope"></i>
                </div>
                <button type="submit" className="btn">Send Reset Link</button>
            </form>
        </div>
    );
}

export default ForgotPassword;