import React from 'react';
import { Box, Typography, Grid, CircularProgress, Alert,styled } from '@mui/material';
import EnhancedArtistCard from './TopMatchedArtistCard';

const BrandedTypography = styled(Typography)(({ theme }) => ({
    '&.brand-heading': {
      color: theme.palette.primary.main,
    },
  }));
  

const RecentDateMatches = ({ loading, error, recentMatches, recentDate }) => {
  return (
    <Box sx={{ mb: 8 }}>
      <Box sx={{ mb: 3 }}>
        <BrandedTypography variant="h4" className="brand-heading" sx={{ mb: 1 }}>
          Matches for {new Date(recentDate).toLocaleDateString()}
        </BrandedTypography>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Top artists available for your most recent date
        </Typography>
      </Box>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mb: 4 }}>
          {error}
        </Alert>
      )}

      {!loading && !error && recentMatches?.length > 0 && (
        <Grid container spacing={4}>
          {recentMatches.map((artist) => (
            <Grid item xs={12} sm={6} md={4} key={artist.id}>
              <EnhancedArtistCard artist={artist} />
            </Grid>
          ))}
        </Grid>
      )}

      {!loading && !error && (!recentMatches || recentMatches.length === 0) && (
        <Typography variant="body1">
          No matches found for your most recent available date.
        </Typography>
      )}
    </Box>
  );
};

export default RecentDateMatches;