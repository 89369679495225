import React, { useState } from 'react';

function VenueProfile() {
  const [venue, setVenue] = useState({
    name: 'Bar 101',
    capacity: '300 people',
    type: 'Bar and live music',
    pastEvents: ['Rock Night', 'Jazz Evening'],
  });

  return (
    <div>
      <h2>{venue.name}</h2>
      <p>Type: {venue.type}</p>
      <p>Capacity: {venue.capacity}</p>
      <h3>Past Events</h3>
      <ul>
        {venue.pastEvents.map(event => <li key={event}>{event}</li>)}
      </ul>
      {/* Here you could add edit functionality */}
    </div>
  );
}

export default VenueProfile;