import React from 'react';
import { 
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  styled
} from '@mui/material';
import { Link } from 'react-router-dom';

const EnhancedFeatureCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  height: 400,
  borderRadius: theme.spacing(4),
  backgroundColor: theme.palette.primary.main,
  overflow: 'hidden',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[10],
    '& .action-button': {
      transform: 'translateY(0)',
      opacity: 1,
    }
  }
}));

const ImageOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(to bottom, rgba(239, 97, 145, 0.3), rgba(239, 97, 145, 0.9))',
  }
});

const ContentWrapper = styled(CardContent)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(4),
  '&:last-child': {
    paddingBottom: theme.spacing(4),
  }
}));

const Tag = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  borderRadius: 20,
  marginBottom: theme.spacing(2),
}));

const ActionButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1.5),
  backgroundColor: '#fff',
  color: theme.palette.primary.main,
  transform: 'translateY(20px)',
  opacity: 0,
  transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  }
}));

const HeaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: '16px'
});

const EnhancedArtistCard = ({ artist, isFeatureCard = false }) => {
  if (!artist) return null;

  const {
    id,
    name,
    match_score,
    location,
    image_url,
    available_dates,
    bio
  } = artist;

  // Calculate the minimum cost from available dates
  const minCost = available_dates
    ?.map(date => parseFloat(date.cost))
    .filter(cost => !isNaN(cost))
    .reduce((min, cost) => Math.min(min, cost), Infinity);

  const costDisplay = isFinite(minCost) ? `$${minCost}/hr` : 'Price not available';

  return (
    <EnhancedFeatureCard elevation={4}>
      <ImageOverlay>
        <Box
          component="img"
          src={image_url || '/default-artist-image.jpg'}
          alt={`${name}'s profile`}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: 0.2,
          }}
        />
      </ImageOverlay>
      
      <ContentWrapper>
        {/* Top Section */}
        <Box>
          <HeaderBox>
            <Tag variant="overline" color="white" sx={{ mb: 0 }}>
              {isFeatureCard ? 'TOP MATCH' : 'SEND MESSAGE'}
            </Tag>
            <Typography 
              variant="overline" 
              sx={{ 
                color: 'white',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                padding: '4px 16px',
                borderRadius: '20px',
                fontWeight: 500
              }}
            >
              {name?.toUpperCase()}
            </Typography>
          </HeaderBox>
          
          <Typography variant="h4" component="h3" sx={{ 
            color: 'white',
            mb: 2,
            fontWeight: 600 
          }}>
            {isFeatureCard ? 'Top Matched Artist' : 'Matched Artist'}
          </Typography>
          
          <Typography variant="h6" sx={{ 
            color: 'rgba(255, 255, 255, 0.9)',
            mb: 1 
          }}>
            {location || 'Location not specified'}
          </Typography>
        </Box>
        
        <ActionButton 
          variant="contained" 
          className="action-button"
          component={Link} // Render Button as Link
          to={`/view_profile/${id}`} // Dynamic route using artist's id
        >
          View Profile
        </ActionButton>
        

        <Typography variant="h5" sx={{ 
          color: 'white',
          fontWeight: 500,
          mt: 2, // Adds top margin for spacing
        }}>
          {match_score}% Match for {costDisplay}
        </Typography>
      </ContentWrapper>
    </EnhancedFeatureCard>
  );
};

export default EnhancedArtistCard;