import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, TextField, Button, List, ListItem, 
  ListItemText, ListItemAvatar, Avatar, IconButton, Chip, 
  CircularProgress 
} from '@mui/material';
import { 
  Search, FilterList, MoreVert, Event, AccessTime, Place 
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import VenueBookingForm from './ImpBookingForm';
import VenueBookingLandingPage from './ImpBookingLandPage';
import VendorBookingCalendar from './ImpBookingCalender';
import ContractsPage from './contractsImp';
import ActiveBiddingsPage from './ImpActiveBids';
import ContractDashboard from './ImpContractDashboard';
import BookingsList from './ImpBookingList';
import { useUser } from '../context/UserContext'; 
import './BookingStylesImp.css';

const VenueBookingDashboard = () => { // Removed profile_id as a prop
  const [currentPage, setCurrentPage] = useState('landing');
  const { user } = useUser();
  const [profileId, setProfileId] = useState(null);
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [profileError, setProfileError] = useState(null);
  const [sortByMatch, setSortByMatch] = useState({ active: false, direction: 'desc' });
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [contractsSelect, setContractsSelect] = useState([]);
  const [selectedContract, setSelectedContract] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [isLoadingContracts, setIsLoadingContracts] = useState(true);
  const [contractsError, setContractsError] = useState(null);
  
  const sortContractsByMatch = (contracts) => {
    if (!contracts || !Array.isArray(contracts)) return [];
    if (!sortByMatch.active) return contracts;
    
    return [...contracts].sort((a, b) => {
      const matchA = parseFloat(a.match_data?.match_score) || 0;
      const matchB = parseFloat(b.match_data?.match_score) || 0;
      return sortByMatch.direction === 'desc' ? matchB - matchA : matchA - matchB;
    });
  };

  const mockVenueProfile = {
  }
  const [booking, setBooking] = useState({
    stageSetup: '',
    startDateTime: null,
    endDateTime: null,
    performanceType: '',
    experienceLevel: '',
    genres: [],
    priceRange: { min: '', max: '' }
  });

  const [bookings, setBookings] = useState([
    // ... your existing bookings data
  ]);

  // Fetch profile_id based on user_id
  useEffect(() => {
    const fetchProfileId = async () => {
      try {
        const response = await axios.get(`https://api.live-and-local.com/get-profile/${user.user_id}`);
        setProfileId(response.data.profile_id); // Adjust based on your API response structure
        setIsProfileLoading(false);
      } catch (error) {
        console.error('Error fetching profile ID:', error);
        setProfileError(error);
        setIsProfileLoading(false);
      }
    };

    if (user && user.user_id) {
      fetchProfileId();
    }
  }, [user]);

  
  const handleSortClick = () => {
    setSortByMatch(prev => ({
      active: true,
      direction: prev.active ? (prev.direction === 'desc' ? 'asc' : 'desc') : 'desc'
    }));
  };

  // Fetch contracts based on profileId
  useEffect(() => {
    const fetchContracts = async () => {
      try {
        console.log('Selected booking', selectedBooking);
        const response = await axios.get(`https://api.live-and-local.com/get-contracts/${selectedBooking.id}`);

        console.log(response)
        console.log(response.data)
        setContracts(response.data);
        setIsLoadingContracts(false);
        console.log("theContracts: ", contracts)

      } catch (error) {
        console.error('Error fetching contracts:', error);
        setContractsError(error);
        setIsLoadingContracts(false);
      }
    };

    fetchContracts();
    if (currentPage === 'contracts' && profileId) {
      fetchContracts();
    }
    console.log('fucking crazy', contracts)
  }, [selectedBooking]);


  // Handle Booking Submission
  const handleBookingSubmit = (bookingData) => {
    console.log('New booking submitted:', bookingData);
    // Simulate adding a new booking (replace with backend integration)
    setBookings(prevBookings => [...prevBookings, {
      id: `LB${Math.floor(Math.random() * 100000)}`, // Generate a random ID for demonstration
      artist: bookingData.performanceType === 'solo' ? 'Solo Artist' : 'Band',
      price: `$${bookingData.priceRange.min} - $${bookingData.priceRange.max}`,
      reviews: Math.floor(Math.random() * 100),
      orders: Math.floor(Math.random() * 1000),
      success: `${Math.floor(Math.random() * 100)}%`
    }]);
    setCurrentPage('viewBookings'); // Redirect to bookings list after submission
  };

  // Handler Functions
  const handleCreateBooking = () => setCurrentPage('createBooking');
  const handleViewBookings = () => setCurrentPage('bookinglist');
  const handleViewContracts = () => setCurrentPage('contracts');
  const handleViewCalender = () => setCurrentPage('calender');
  const handleBackToLanding = () => setCurrentPage('landing');
  const handleViewActiveBiddings = () => setCurrentPage('activeBiddings');

  const handleContractClick = (contract) => {
    console.log("the selected contract", contract);
    setSelectedContract(contract);
  };

  const handleBackToContracts = () => {
    setSelectedContract(null);
  };

  // Loading and Error States for Profile
  if (isProfileLoading) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh' 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (profileError) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh' 
        }}
      >
        <Typography variant="h6" color="error">
          Failed to load profile. Please try again later.
        </Typography>
      </Box>
    );
  }

  // Handle selectedContract rendering
  if (selectedContract) {
    console.log(selectedContract)
    return <ContractDashboard contract={selectedContract} onBack={handleBackToContracts} page = {'Booking'}/>;
  }



  return (
    <Box className="venue-booking-container" sx={{ display: 'flex' }}>
      {/* Sidebar */}
      <Box 
        className="venue-sidebar" 
        sx={{ 
          width: '240px', 
          bgcolor: '#e0e0e0', 
          color: '#fff', 
          minHeight: '100vh', 
          padding: 2 
        }}
      >
        <Typography 
          variant="h6" 
          sx={{ 
            mb: 2, 
            cursor: 'pointer', 
            fontWeight: 'bold',
            '&:hover': {
              textDecoration: 'underline',
            }
          }}
          onClick={handleBackToLanding}
        >
          Booking Dashboard
        </Typography>
        <List>
          {['Create Booking', 'Your Bookings', 'Calendar', 'Active Bids','Contracts', 'Finances'].map((text) => (
            <ListItem 
              button 
              key={text} 
              sx={{ 
                fontFamily: "'Times New Roman', Times, serif", 
                color: '#000000' 
              }}
              onClick={() => {
                switch(text) {
                  case 'Your Bookings':
                    handleViewBookings();
                    break;
                  case 'Contracts':
                    handleViewContracts();
                    break;
                  case 'Create Booking':
                    handleCreateBooking();
                    break;
                  case 'Calendar':
                    handleViewCalender();
                    break;
                  case 'Active Bids':
                    handleViewActiveBiddings();
                    break;
                  default:
                    handleBackToLanding();
                }
              }}
            >
              <ListItemText 
                primary={
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {text}
                  </Typography>
                } 
              />
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Main Content */}
      <Box className="venue-main-content" sx={{ flex: 1, padding: 4 }}>
        {currentPage === 'landing' && (
          <VenueBookingLandingPage 
            onCreateBooking={handleCreateBooking} 
            onViewBookings={handleViewBookings} 
            onViewContracts={handleViewContracts} // Optional if you want buttons on landing page to view contracts
            venue_user_id={user.user_id} // Use the fetched profileId
            venue_profile_id={profileId}

          />
        )}

        {currentPage === 'createBooking' && (
          <Box>
            {/* Header for Booking Form */}
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 3 
              }}
            >
              <Typography variant="h4">Create a New Booking</Typography>
              <Button 
                className="custom-button"
                onClick={() => setCurrentPage('landing')}
                variant="outlined"
                color="secondary"
              >
                Cancel
              </Button>
            </Box>
            {/* Booking Form Component */}
            <VenueBookingForm 
              userId={profileId} // Use user_id from context
              venueProfile={mockVenueProfile} 
              onSubmit={handleBookingSubmit} 
            />
          </Box>
        )}

        {currentPage === 'viewSingleBooking' && selectedBooking && (
          <Box className="booking-list">
            {/* Header for Bookings List */}
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 3 
              }}
            >
              <Button onClick={() => setCurrentPage('bookinglist')}>Back to Bookings</Button>
            </Box>

            {/* Search and Filter Section */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <TextField 
                placeholder="Search bookings..." 
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: <Search />,
                }}
                sx={{ width: '60%' }}
              />
              <Box>
              <Button 
                    variant="outlined" 
                    sx={{ 
                      mr: 1,
                      bgcolor: sortByMatch.active ? '#EF6191' : 'transparent',
                      color: sortByMatch.active ? 'white' : 'inherit',
                      '&:hover': { 
                        bgcolor: sortByMatch.active ? '#c45577' : 'rgba(239, 97, 145, 0.1)' 
                      }
                    }}
                    onClick={handleSortClick}
                  >
                    Percent Match {sortByMatch.active ? (sortByMatch.direction === 'desc' ? '↓' : '↑') : ''}
                  </Button>
              </Box>
            </Box>

            Enhanced Booking Card Section

            {/* Live Booking Card */}
            <Box
              className="live-booking-card"
              sx={{
                mb: 4,
                borderRadius: '16px',
                overflow: 'hidden',
                boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                height: { xs: 'auto', md: '500px' },
                position: 'relative'
              }}
            >
              <Link
                to={`/view_profile/${selectedBooking.profile_id}`}
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  display: 'block',
                  height: '100%'
                }}
              >
                {/* Background Image */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${selectedBooking.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: 'linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.8))',
                      zIndex: 1
                    }
                  }}
                />

                {/* Capacity Badge */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    bgcolor: 'rgba(255,255,255,0.9)',
                    borderRadius: '12px',
                    padding: '8px 16px',
                    zIndex: 2,
                    backdropFilter: 'blur(4px)'
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Capacity: {selectedBooking.capacity}
                  </Typography>
                </Box>

                {/* Content Container */}
                <Box 
                  sx={{ 
                    position: 'relative',
                    zIndex: 2,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    padding: 4,
                    color: 'white'
                  }}
                >
                  {/* Header */}
                  <Typography 
                    variant="h3" 
                    sx={{ 
                      fontWeight: 'bold',
                      mb: 2,
                      textShadow: '2px 2px 4px rgba(0,0,0,0.3)'
                    }}
                  >
                    {selectedBooking.profile_name}
                  </Typography>

                  {/* Info Grid */}
                  <Box 
                    sx={{ 
                      display: 'grid',
                      gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
                      gap: 3,
                      mb: 3
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Place sx={{ color: 'white' }} />
                      <Typography>{selectedBooking.location}</Typography>
                    </Box>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Event sx={{ color: 'white' }} />
                      <Typography>{selectedBooking.date}</Typography>
                    </Box>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <AccessTime sx={{ color: 'white' }} />
                      <Typography>{`${selectedBooking.startTime} - ${selectedBooking.endTime}`}</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography sx={{ fontWeight: '500' }}>
                        ${selectedBooking.details.priceRange.min} - ${selectedBooking.details.priceRange.max}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Tags Section */}
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    {/* Experience Level */}
                    <Box 
                      sx={{ 
                        bgcolor: 'rgba(255,255,255,0.2)',
                        backdropFilter: 'blur(4px)',
                        px: 2,
                        py: 1,
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                      }}
                    >
                      <Typography 
                        sx={{
                          color: '#fff'  // This was missing the '#' before
                        }}
                        variant="body2"
                      >
                        Experience:
                      </Typography>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          fontWeight: 'bold',
                          color: '#fff'  // Add this to make the second Typography white too
                        }}
                      >
                        {selectedBooking.details.experienceLevel}
                      </Typography>
                    </Box>

                    {/* Genres */}
                    {selectedBooking.details.genres.map((genre, index) => (
                      <Box
                        key={index}
                        sx={{
                          bgcolor: 'rgba(255,255,255,0.2)',
                          backdropFilter: 'blur(4px)',
                          px: 2,
                          py: 1,
                          borderRadius: '8px'
                        }}
                      >
                        <Typography 
                        sx={{
                          color: '#fff',
                          fontWeight: 'bold'  // This was missing the '#' before
                        }}
                        variant="body2"
                      >{genre}</Typography>
                      </Box>
                    ))}

                    {/* Venue Types */}
                    {Array.isArray(selectedBooking.venue_type) ? 
                      selectedBooking.venue_type.map((type, index) => (
                        <Box
                          key={index}
                          sx={{
                            bgcolor: 'rgba(255,255,255,0.2)',
                            backdropFilter: 'blur(4px)',
                            px: 2,
                            py: 1,
                            borderRadius: '8px'
                          }}
                        >
                          <Typography 
                            sx={{
                              color: '#fff',
                              fontWeight: 'bold'  // This was missing the '#' before
                            }}
                            variant="body2"
                          >{type}</Typography>
                        </Box>
                      )) : 
                      <Box
                        sx={{
                          bgcolor: 'rgba(255,255,255,0.2)',
                          backdropFilter: 'blur(4px)',
                          px: 2,
                          py: 1,
                          borderRadius: '8px'
                        }}
                      >
                        <Typography 
                        sx={{
                          color: '#fff',
                          fontWeight: 'bold'  // This was missing the '#' before
                        }}
                        variant="body2"
                      >{selectedBooking.venue_type}</Typography>
                      </Box>
                    }
                  </Box>
                </Box>
              </Link>
            </Box>
                                    


            <List>
                {sortContractsByMatch(contracts).map((contract) => (
                  <ListItem
                    key={contract.id}
                    className="booking-list-item"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '16px',
                      margin: '8px 0',
                      backgroundColor: '#fff',
                      borderRadius: '8px',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: '#EF6191' }}>
                          {contract.other_party.name[0]}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={<Typography variant="h6">{contract.other_party.name}</Typography>}
                        secondary={
                          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
                            <Typography variant="body2" color="text.secondary">
                              {contract.other_party.total_reviews} Reviews • {contract.other_party.total_orders} Orders
                            </Typography>
                            <Chip
                              label={`${(contract.match_data.match_score).toFixed(1)}% Match`}
                              size="small"
                              sx={{
                                mt: 1,
                                bgcolor: '#e8f5e9',
                                color: '#2e7d32',
                                maxWidth: 'fit-content'
                              }}
                            />
                          </Box>
                        }
                      />
                    </Box>
                    
                    {/* Additional Contract Details */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minWidth: '200px', mr: 2 }}>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Bid Amount: ${contract.bid_amount}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Experience: {contract.booking_details.experienceLevel}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Proposed Date: {contract.proposed_date}
                      </Typography>
                    </Box>

                    {/* Action Buttons */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Button 
                        variant="contained" 
                        sx={{ 
                          bgcolor: '#EF6191', 
                          '&:hover': { bgcolor: '#c45577' } 
                        }}
                        onClick={() => handleContractClick(contract)}
                      >
                        View Details
                      </Button>
                      <IconButton>
                        <MoreVert />
                      </IconButton>
                    </Box>
                  </ListItem>
                ))}
              </List>
            

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button 
                variant="contained" 
                sx={{ 
                  bgcolor: '#EF6191', 
                  '&:hover': { bgcolor: '#c45577' } 
                }}
              >
                Edit Booking
              </Button>
            </Box>
          </Box>
        )}

        {currentPage === 'bookinglist' && (
          <BookingsList 
            setSelectedBooking={setSelectedBooking} 
            setCurrentPage={setCurrentPage} 
            profile_id={profileId} // Pass profileId if needed
          />
        )}

        {currentPage === 'calender' && (
          <VendorBookingCalendar 
            profileId={profileId} // Pass profileId if needed
          />
        )}

        {currentPage === 'contracts' && (
          <ContractsPage 
            profileId={user.user_id} // Pass profileId if needed
          />
        )}

        {currentPage === 'activeBiddings' && (
          <ActiveBiddingsPage 
            setSelectedBooking={setSelectedBooking} 
            setCurrentPage={setCurrentPage} 
            profileId={profileId} // Pass profileId if needed
          />
        )}
      </Box>
    </Box>
  );
};

export default VenueBookingDashboard;
