import React, { useContext } from 'react';
import { DataContext } from './DataContext';
import CategoryPreview from './CategoryPreview';

function PerformersComponent() {
    const { performers } = useContext(DataContext);
    return (
        <div>
            <CategoryPreview title="All Performers" items={performers} />
        </div>
    );
}

export default PerformersComponent;