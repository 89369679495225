import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import './UserProfile.css';  // Assuming the CSS for user profile is in this file
import AvailableDatesPicker from './AvailableDatesPicker';

function ViewProfile() {
    const { profile_id } = useParams();
    const navigate = useNavigate();
    const [profile, setProfile] = useState({
        name: '',
        bio: '',
        image_url: '',
        additional_images: [],
        location: '',
        type: '',
        availableDates: [],
        bookedDates: [],
        setting: '',
        average_attendance: '',
        genre: '',
        capacity: '',
        amenities: '',
        experience_level: '',
        preferred_venues: '',
        stageTypes: '',
        preferred_contact_method: '',
        phone_number: '',
        performance_type: ''
    });
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const renderImages = () => {
        if (profile.type === 'vendor') {
            if (profile.additional_images && profile.additional_images.length > 0) {
                return (
                    <div className="profile-carousel">
                        <Slider {...settings}>
                            <div>
                                <img src={profile.image_url || '/default-image.png'} alt={profile.name} />
                            </div>
                            {profile.additional_images.map((imageUrl, index) => (
                                <div key={index}>
                                    <img src={imageUrl} alt={`${profile.name} ${index + 1}`} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                );
            } 
        }
        return null;
    };

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`https://api.live-and-local.com/view_profile/${profile_id}`);
                const data = response.data;
                console.log(data)
                setProfile({
                    ...data,
                    availableDates: data.availableDates || [],
                    bookedDates: data.bookedDates || [],
                    setting: data.setting || '',
                    average_attendance: data.average_attendance || '',
                    genre: data.genre || '',
                    additional_images: data.additional_images || []
                });

                setLoading(false);
            } catch (err) {
                console.error("Failed to fetch profile data:", err);
                setError('Failed to fetch profile data');
                setLoading(false);
            }
        };

        fetchProfile();
    }, [profile_id]);

    const handleBookNow = () => {
        // Check if user is logged in
        axios.get('https://api.live-and-local.com/check_login_status', { withCredentials: true })
            .then(response => {
                if (response.data.logged_in) {
                    navigate(`/book/${profile_id}`);
                } else {
                    navigate('/login');
                }
            })
            .catch(err => {
                console.error('Failed to check login status:', err);
                navigate('/login');
            });
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <> {profile.type === 'vendor' && (
            renderImages()
        )}
            <div className="user-profile">
                <img src={profile.image_url || '/default-image.png'} alt={profile.name} style={{ maxWidth: '300px', borderRadius: '10px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)' }} />
                <h2>{profile.name || 'Default Name'}</h2>
                <p>Bio: {profile.bio || 'No bio available'}</p>
                <p>Location: {profile.location || 'No location available'}</p>
                <p>Type: {profile.type || 'No type specified'}</p>
                <p>Preferred Contact Method: {profile.preferred_contact_method || 'No contact method specified'}</p>

                {profile.type === 'vendor' && (
                    <>
                        <p>Setting: {profile.setting || 'No setting specified'}</p>
                        <p>Stage Types: {profile.stageTypes || 'No stage type specified'}</p>
                        <p>Capacity: {profile.capacity || 'No capacity specified'}</p>
                        <p>Other Notes: {profile.amenities || 'No amamenties specified'}</p>
                        <p>Average Attendance: {profile.average_attendance || 'No average attendance available'}</p>
                    </>
                )}

                {profile.type === 'performer' && (
                    <><p>Genre: {profile.genre || 'No genre specified'}</p>
                        <p>Location: {profile.location || 'No location available'}</p>
                        <p>Performance Type: {profile.performance_type || 'No type available'}</p>
                        <p>Experience: {profile.experience_level || 'No Experience available'}</p>
                        <p>Preferred Venues Types: {profile.preferred_venues || 'No preferred_venues available'}</p>
                    </>
                )}

                <button onClick={handleBookNow}>Book Now</button>
                <AvailableDatesPicker
                    availableDates={profile.availableDates || []}
                    bookedDates={profile.bookedDates || []}
                    setAvailableDates={(dates) => setProfile({ ...profile, availableDates: dates })}
                    profileId={profile_id}
                    isEditable={false}
                    isViewProfile={true} />
            </div></>
    );
}

export default ViewProfile;