import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Chip } from '@mui/material';

const BookingDetailsModal = ({ booking, onClose, onBid }) => {
  return (
    <Dialog open={Boolean(booking)} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{booking.title}</DialogTitle>
      <DialogContent dividers>
        <Typography variant="subtitle1" gutterBottom>
          Date: {booking.date}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Location: {booking.location}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Experience Level: {booking.details.experienceLevel}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Price Range: ${booking.details.priceRange.min} - ${booking.details.priceRange.max}
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">Genres:</Typography>
          {booking.details.genres.map((genre) => (
            <Chip key={genre} label={genre} sx={{ mr: 1, mt: 1 }} />
          ))}
        </Box>
        {/* Add other booking details as needed */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button variant="contained" onClick={onBid}>
          Bid on this Booking
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingDetailsModal;