import React, { useState }from 'react';
import { Container, Typography, Grid, Link, Box, Popover } from '@mui/material';
import './Footer.css';

function Footer() {
    const [termsAnchorEl, setTermsAnchorEl] = useState(null);
    const [dataModelsAnchorEl, setDataModelsAnchorEl] = useState(null);

    const handleTermsPopoverOpen = (event) => {
        setTermsAnchorEl(event.currentTarget);
    };

    const handleTermsPopoverClose = () => {
        setTermsAnchorEl(null);
    };

    const handleDataModelsPopoverOpen = (event) => {
        setDataModelsAnchorEl(event.currentTarget);
    };

    const handleDataModelsPopoverClose = () => {
        setDataModelsAnchorEl(null);
    };

    const termsOpen = Boolean(termsAnchorEl);
    const dataModelsOpen = Boolean(dataModelsAnchorEl);

    return (
        <footer className='footer'>
          <Container maxWidth="lg">
            <Box py={.2} /* Reduced padding */ textAlign="center" borderTop={1} borderColor="grey.200">
              <Grid container spacing={2} /* Reduced spacing */ justifyContent="center">
                <Grid item xs={12} sm={4}>
                  <Typography variant="caption" display="block" gutterBottom>
                    © 2024 Live & Local
                  </Typography>
                  <Link href="mailto:liveandlocalapp@gmail.com" variant="caption">
                    Contact Us
                  </Link>
                </Grid>
                <Grid item xs={12} sm={4}>
                <Link variant="caption" onClick={handleTermsPopoverOpen} style={{ cursor: 'pointer' }}>
                  Terms of Service
                </Link>
                <Popover
                  open={termsOpen}
                  anchorEl={termsAnchorEl}
                  onClose={handleTermsPopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box p={1}> 
                    <Typography variant="body2"> 
                      All users must act act in accordance with the law.
                    </Typography>
                    <Typography variant="body2">
                      This app is the property of liveandlocalapp.com and models, data, or LMs used are property of licensors, or user in terms of profile.
                    </Typography>
                    <Typography variant="body2">
                      All uses users must conduct themselves respectfully or be subject to removal.
                    </Typography>
                  </Box>
                </Popover>
              </Grid>
              <Grid item xs={12} sm={4}>
              <Link variant="caption" href="/aboutus" style={{ cursor: 'pointer' }}>
                    About Us
                </Link>
              </Grid>
            </Grid> 
            <Box mt={.2}>
            </Box>
          </Box>
      </Container>
    </footer>
  );
}

    
export default Footer;