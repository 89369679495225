import React, { useState, useEffect } from 'react';
import {
    Box, Typography, List, ListItem, Grid, IconButton, Divider, Dialog, DialogContent, DialogTitle, DialogActions, Button
} from '@mui/material';
import { CheckCircle, Info, MoreVert, Close, Create } from '@mui/icons-material';
import './BookingStylesImp.css';
import axios from "axios";
import ArtistContractDashboard from './ArtistBookingContract';

const BookingsList = () => {
    const [bookings, setBookings] = useState([]);
    const [pendingBids, setPendingBids] = useState([]);
    const [bookedBookings, setBookedBookings] = useState([]);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const response = await axios.post('https://api.live-and-local.com/getArtistBookings', {}, { withCredentials: true });
                const fetchedBookings = response.data;
                setBookings(fetchedBookings);
                setPendingBids(fetchedBookings.filter(booking => booking.status === 'Pending'));
                setBookedBookings(fetchedBookings.filter(booking => booking.status === 'Booked'));
            } catch (error) {
                console.error('Error fetching bookings data:', error);
            }
        };
        fetchBookings();
    }, []);

    const handleIconClick = (booking) => {
        switch (booking.status) {
            case 'Pending':
                handleAvailableAction(booking.contract_id);
                break;
            case 'Booked':
                handleBookedAction(booking);
                break;
            default:
                console.log('Unknown status');
        }
    };

    const handleAvailableAction = async (contract_id) => {
        const isConfirmed = window.confirm('Are you sure you want to remove this bid?');
        if (isConfirmed) {
            try {
                await axios.delete(`https://api.live-and-local.com/deleteBid/${contract_id}`);
                alert('Bid deleted successfully.');
                // Refresh the bookings list after deletion
                const updatedBookings = bookings.filter(booking => booking.contract_id !== contract_id);
                setBookings(updatedBookings);
                setPendingBids(updatedBookings.filter(booking => booking.status === 'Pending'));
                setBookedBookings(updatedBookings.filter(booking => booking.status === 'Booked'));
            } catch (error) {
                console.error('Error deleting bid:', error);
                alert('There was an error deleting the bid.');
            }
        }
    };

    const handleBookedAction = (booking) => {
        setSelectedBooking(booking);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const getActionIcon = (status, booking) => {
        switch (status) {
            case 'Pending':
                return (
                    <IconButton onClick={() => handleIconClick(booking)}>
                        <Close />
                    </IconButton>
                );
            case 'Booked':
                return (
                    <IconButton onClick={() => handleIconClick(booking)}>
                        {!!booking.artist_signed ? <MoreVert /> : <Create />}
                    </IconButton>
                );
            default:
                return (
                    <IconButton disabled>
                        <Info />
                    </IconButton>
                );
        }
    };

    const handleBookingClick = (booking) => {
        console.log('WHAT THE FUCK')
        console.log(booking)
        setSelectedBooking(booking);
        console.log(selectedBooking)
        setIsModalOpen(true);
    };

    const handleBackToContracts = () => {
        setSelectedBooking(null);
        setIsModalOpen(false);
      };

    if (selectedBooking) {
        console.log('BEING SENT TO CONTRACT', selectedBooking);
        return <ArtistContractDashboard contract={selectedBooking } onBack={handleBackToContracts} page={'Contracts'}  />;
      }

    const renderBookingRow = (booking) => (
        <ListItem
            key={booking.id}
            className="booking-row"
            sx={{ paddingY: 1, cursor: 'pointer' }}
            onClick={() => handleBookingClick(booking)}
        >
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} sm={2}>
                    <Typography variant="body2">{booking.name}</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Typography variant="body2">{new Date(booking.date).toLocaleDateString()}</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Typography variant="body2">{`${booking.start_time} - ${booking.end_time}`}</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Typography variant="body2">{booking.location}</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Typography variant="body2">{`$${booking.bid_amount}`}</Typography>
                </Grid>
                <Grid item xs={12} sm={1}>
                    {getActionIcon(booking.status, booking)}
                </Grid>
            </Grid>
            <Divider sx={{ mt: 1 }} />
        </ListItem>
    );

    return (
        <Box className="bookings-list-page" sx={{ p: { xs: 2, sm: 3 }, backgroundColor: '#f9f9f9' }}>
            <Box className="bookings-header" sx={{ mb: 4, textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom>Your Bookings</Typography>
                <Typography variant="body1" color="text.secondary">Total Bookings: {bookings.length}</Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
                <Grid container spacing={2} alignItems="center" sx={{ backgroundColor: '#e0e0e0', borderRadius: 1 }}>
                    <Grid item xs={12} sm={2}>
                        <Typography variant="subtitle1" fontWeight="bold">Venue Name</Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Typography variant="subtitle1" fontWeight="bold">Date</Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Typography variant="subtitle1" fontWeight="bold">Time</Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Typography variant="subtitle1" fontWeight="bold">Location</Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Typography variant="subtitle1" fontWeight="bold">Bid Amount</Typography>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <Typography variant="subtitle1" fontWeight="bold">Actions</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box className="booking-section" sx={{ mb: 4 }}>
                <Typography variant="h5" gutterBottom>Pending Bids</Typography>
                {pendingBids.length > 0 ? (
                    <List>
                        {pendingBids.map(renderBookingRow)}
                    </List>
                ) : (
                    <Typography variant="body2" color="text.secondary">No pending bids.</Typography>
                )}
            </Box>
            <Box className="booking-section" sx={{ mb: 4 }}>
                <Typography variant="h5" gutterBottom>Booked Dates</Typography>
                {bookedBookings.length > 0 ? (
                    <List>
                        {bookedBookings.map(renderBookingRow)}
                    </List>
                ) : (
                    <Typography variant="body2" color="text.secondary">No booked dates.</Typography>
                )}
            </Box>

            {/* Modal for ArtistContractDashboard */}
            <Dialog
                open={isModalOpen}
                onClose={handleCloseModal}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>Contract Details</DialogTitle>
                <DialogContent>
                    {selectedBooking && (
                        <ArtistContractDashboard booking={selectedBooking} />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default BookingsList;