import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import ArtistContractDashboard from './ArtistBookingContract';
import ContractDashboard from './ImpContractDashboard';
import axios from 'axios';

const ContractRoute = () => {
    const { id } = useParams();  // Gets the contract ID from the URL
    const navigate = useNavigate();

    const [contract, setContract] = useState(null);
    const [loading, setLoading] = useState(true);
    const user = null

    console.log("Contract ID:", id);  // Add this

    console.log('here')
    useEffect(() => {
        console.log("Effect running"); 
        const fetchContract = async () => {
            try {
                console.log('Here')
                console.log("Fetching contract...");  // Add this
                const url = `https://api.live-and-local.com/contracts/${id}`;
                console.log("URL:", url);  // Add this
                const response = await axios.get(url, {
                    withCredentials: true
                });
                console.log("Response:", response);  // Add this
                setContract(response.data);

                setContract(response.data);
            } catch (error) {
                console.error('Error fetching contract:', error);
                // Handle error - maybe navigate to an error page
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchContract();
        }
    }, [id, ]);

    const handleBackToContracts = () => {
        navigate(-1); // Goes back to previous page
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!contract) {
        return <div>Contract not found</div>;
    }

    // Render different dashboard based on user type
    if (contract?.user_type === 'performer') {
        return (
            <ArtistContractDashboard 
                contract={contract} 
                onBack={handleBackToContracts}
                page={'Messages'}
            />
        );
    } else if (contract?.user_type === 'vendor') {
        return (
            <ContractDashboard 
                contract={contract} 
                onBack={handleBackToContracts}
                page={'Message'}
            />
        );
    }

    return <div>Unknown user type</div>;
};

export default ContractRoute;