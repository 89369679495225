import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Grid,
  Snackbar,
  Alert,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  Box
} from '@mui/material';
import {
  Event,
  Schedule,
  Place,
  AttachMoney,
} from '@mui/icons-material';

const ContractPopup = ({ booking, onClose }) => {
  const [bidAmount, setBidAmount] = useState('');
  const [artistNotes, setArtistNotes] = useState('');
  const [technicalRequirements, setTechnicalRequirements] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleBidSubmit = () => {
    setIsSubmitting(true);
    const bidData = {
      bookingId: booking.id,
      bidAmount: bidAmount,
      technicalRequirements: technicalRequirements,
      artistNotes: artistNotes,
    };

    fetch('https://api.live-and-local.com/api/submit-bid', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bidData),
      credentials: 'include',
    })
      .then((data) => {
        console.log('Bid submitted:', data);
        setSnackbarOpen(true);
        setBidAmount('');
        setArtistNotes('');
      })
      .catch((error) => {
        console.error('Error submitting bid:', error);
        setErrorMessage('Failed to submit bid. Please try again.');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'warning';
      default:
        return 'default';
    }
  };

  return (
    <>
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Button onClick={onClose} variant="outlined" sx={{ mb: 2 }}>
          Back to Contracts
        </Button>
      </DialogTitle>
      <DialogContent dividers>
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={8}>
              <Typography variant="h4" gutterBottom>{booking.venue_name}</Typography>
              <Typography variant="h6" color="text.secondary">{booking.venue_name}</Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
              <Chip 
                label="Pending"
                color={getStatusColor('pending')}
                sx={{ fontSize: '1rem', p: 1 }}
              />
            </Grid>
          </Grid>
        </Paper>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>Event Details</Typography>
              <List>
                <ListItem>
                  <Event sx={{ mr: 2 }} />
                  <ListItemText primary="Date" secondary={booking.date} />
                </ListItem>
                <ListItem>
                  <Schedule sx={{ mr: 2 }} />
                  <ListItemText primary="Time" secondary={booking.time} />
                </ListItem>
                <ListItem>
                  <Place sx={{ mr: 2 }} />
                  <ListItemText primary="Venue" secondary={booking.location} />
                </ListItem>
                <ListItem>
                  <AttachMoney sx={{ mr: 2 }} />
                  <ListItemText primary="Price Range" secondary={`$${booking.details.priceRange.min} - $${booking.details.priceRange.max}`} />
                </ListItem>
              </List>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>Performance Requirements</Typography>
              <List>
                <ListItem>
                  <ListItemText primary="Venue Type" secondary={booking.title} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Experience Level" secondary={booking.details.experienceLevel} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Genres Preferred" secondary={booking.details.genres.join(', ')} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Capacity" secondary={booking.capacity} />
                </ListItem>
              </List>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>Your Proposal</Typography>
              <TextField
                label="Your Bid Amount"
                type="number"
                fullWidth
                value={bidAmount}
                onChange={(e) => setBidAmount(e.target.value)}
                InputProps={{ startAdornment: '$' }}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Technical Requirements"
                multiline
                rows={3}
                fullWidth
                value={technicalRequirements}
                onChange={(e) => setTechnicalRequirements(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Additional Notes"
                multiline
                rows={4}
                fullWidth
                value={artistNotes}
                onChange={(e) => setArtistNotes(e.target.value)}
              />
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', p: 2 }}>
        <Button variant="contained" color="primary" onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleBidSubmit}
              disabled={!bidAmount || isSubmitting}
            >
              Submit Proposal
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
          Your bid was submitted successfully!
        </Alert>
      </Snackbar>
      {errorMessage && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default ContractPopup;