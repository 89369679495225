import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './Map.css'; 
import { Link } from 'react-router-dom';
import marker from '../MapLogo.svg';
import { useVenues } from './FetchMapData';  // Import the custom hook

export default function VendorMap({ center, zoom, scrollWheelZoom, style, customVenues }) {
    const { venues, loading, error } = useVenues();
    
    const myIcon = new L.Icon({
        iconUrl: marker,
        iconRetinaUrl: marker,
        popupAnchor: [-0, -0],
        iconSize: [32, 45]
    });

    const displayVenues = customVenues || venues;

    console.log('Display Venues:', displayVenues);

    if (!center && displayVenues.length > 0) {
        let sumLat = 0;
        let sumLong = 0;
        let count = 0;

        displayVenues.forEach(venue => {
            if (venue.latitude !== 0 && venue.longitude !== 0) {
                sumLat += parseFloat(venue.latitude);
                sumLong += parseFloat(venue.longitude);
                count += 1;
            }
        });

        let avgLat = (sumLat / count).toFixed(4);
        let avgLong = (sumLong / count).toFixed(4);
        center = [avgLat, avgLong];
    }

    console.log('Center:', center);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <MapContainer
            center={center || [42.3601, -71.0589]} // Default to Boston coordinates
            zoom={zoom || 13}
            scrollWheelZoom={scrollWheelZoom !== undefined ? scrollWheelZoom : false}
            style={style || { height: "80vh", width: "100%", marginLeft: 15, marginRight: 15 }}
        >
            <TileLayer
                attribution='&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
                url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
            />
            {displayVenues.map((venue) => (
                venue.latitude !== 0 && venue.longitude !== 0 && (
                    <Marker
                        key={venue.id}
                        position={[venue.latitude, venue.longitude]}
                        icon={myIcon}
                    >
                        <Popup>
                            <Link to={`/view_profile/${venue.id}`} className="venue-popup-link">
                                {venue.image && <img src={venue.image} style={{ width: 300 }} alt="" />}
                                <h1>{venue.name}</h1>
                                <h2>{venue.address}</h2>
                                {venue.setting && <h3>{venue.setting}</h3>}
                                {venue.genre && <p>Genre: {venue.genre}</p>}
                                <p>Click to view profile</p>
                            </Link>
                        </Popup>
                    </Marker>
                )
            ))}
        </MapContainer>
    );
}