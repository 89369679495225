import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import './VendorBookingCalendarImp.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip } from '@mui/material';
import VenueBookingForm from './ImpBookingForm';

const localizer = momentLocalizer(moment);

const eventStyleGetter = (event, start, end, isSelected) => {
  let style = {
    backgroundColor: '#4caf50', // Default color for available dates
    borderRadius: '0px',
    opacity: 0.8,
    color: 'white',
    border: '0px',   
    display: 'block'
  };

  if (event.isBooked) {
    style.backgroundColor = '#CC0000'; // Red for booked dates
  } else if (event.interestShown) {
    style.backgroundColor = '#2196f3'; // Blue for dates with interest shown
  }

  return { style };
};

export default function VendorBookingCalendar({ profileId }) {
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  // Move fetchBookings outside of useEffect
  const fetchBookings = async () => {
    try {
      const response = await fetch(`https://api.live-and-local.com/impget-bookings/${profileId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch bookings');
      }
      const data = await response.json();
      // Map the bookings data to calendar events
      const mappedEvents = data.map(booking => {
        const start = moment(`${booking.date} ${booking.startTime}`, 'YYYY-MM-DD HH:mm').toDate();
        const end = moment(`${booking.date} ${booking.endTime}`, 'YYYY-MM-DD HH:mm').toDate();
        return {
          id: booking.id,
          title: `${booking.status}: ${booking.details.performanceType}`,
          start: start,
          end: end,
          isBooked: booking.status.toLowerCase() === 'booked',
          interestShown: booking.status.toLowerCase() === 'interest_shown',
          venue: booking.venue || 'Main Stage',
          description: booking.details.description || booking.description || '',
          capacity: booking.capacity || 500,
          genres: booking.details.genres || [],
          techSpecs: booking.details.techSpecs || {}
        };
      });
      setEvents(mappedEvents);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };

  useEffect(() => {
    fetchBookings();
  }, [profileId]); // Include userId in dependency array if it can change

  const handleSelectSlot = (slotInfo) => {
    setSelectedDate(slotInfo.start);
    setShowDialog(true);
  };

  const handleCreateBooking = async (bookingData) => {
    try {
      // Send bookingData to backend to create a new booking
      const response = await fetch(`https://api.live-and-local.com/impcreate-booking/${profileId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bookingData)
      });
      if (!response.ok) {
        throw new Error('Failed to create booking');
      }
      // Fetch updated bookings list
      await fetchBookings(); // Call fetchBookings here
      setShowDialog(false);
    } catch (error) {
      console.error('Error creating booking:', error);
    }
  };

  const EventComponent = ({ event }) => (
    <Tooltip
      title={
        <div>
          <strong>{event.title}</strong>
          <p>Venue: {event.venue || 'N/A'}</p>
          <p>Time: {moment(event.start).format('h:mm A')} - {moment(event.end).format('h:mm A')}</p>
          <p>Capacity: {event.capacity || 'N/A'}</p>
          <p>Genres: {event.genres && event.genres.length > 0 ? event.genres.join(', ') : 'N/A'}</p>
          <p>Description: {event.description || 'N/A'}</p>
          <p>Sound: {event.techSpecs?.soundSystem || 'N/A'}</p>
          <p>Lighting: {event.techSpecs?.lighting || 'N/A'}</p>
        </div>
      }
      arrow
    >
      <div>{event.title}</div>
    </Tooltip>
  );

  const mockVenueProfile = {
    id: "v12345",
    name: "The Rhythm Lounge",
    // ... rest of the profile data ...
  };

  return (
    <div style={{ 
      height: '100%',
      width: '100%',
      padding: '1rem', 
      boxSizing: 'border-box',
      overflow: 'hidden'
    }}>
      <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>Vendor Booking Calendar</h1>
      <div style={{ 
        height: 'calc(100% - 3rem)', 
        width: '100%', 
        overflowX: 'auto',
        overflowY: 'auto'
      }}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ minWidth: '800px', height: '100%' }}
          selectable
          onSelectSlot={handleSelectSlot}
          eventPropGetter={eventStyleGetter}
          components={{
            event: EventComponent
          }}
          defaultDate={new Date()}
        />
      </div>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>Create New Booking</DialogTitle>
        <DialogContent>
          <VenueBookingForm
            userId={profileId}
            venueProfile={mockVenueProfile} 
            onSubmit={handleCreateBooking}
            selectedDate={selectedDate}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}