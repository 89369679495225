import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';

const Geosuggest = ({ onSelect }) => {
    const [suggestions, setSuggestions] = useState([]);
    const [value, setValue] = useState('');

    const onSuggestionsFetchRequested = async ({ value }) => {
        if (value.length > 2) {
            try {
                const response = await axios.get(`https://api.live-and-local.com/geocode?q=${value}`);
                setSuggestions(
                    response.data.results.map((result) => ({
                        label: result.formatted,
                        location: result.geometry,
                    }))
                );
            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        }
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const getSuggestionValue = (suggestion) => suggestion.label;

    const renderSuggestion = (suggestion) => <div>{suggestion.label}</div>;

    const onChange = (event, { newValue }) => {
        setValue(newValue);
    };

    const onSuggestionSelected = (event, { suggestion }) => {
        onSelect(suggestion);
    };

    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{
                placeholder: 'Search for a location',
                value,
                onChange,
            }}
            onSuggestionSelected={onSuggestionSelected}
        />
    );
};

export default Geosuggest;