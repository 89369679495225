import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext'; // Make sure the path is correct
import './AuthStyles.css'; // Ensuring your custom styles are applied
import ClearDBButton from './killbutton';
import { Link } from 'react-router-dom';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useUser(); // Use the login function from the context

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://api.live-and-local.com/login', {
                username: username,
                password: password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });

            if (response.data.user_id) {
                login(response.data); // Update the global user state on successful login
                navigate(response.data.redirect_url || '/'); // Navigate using the provided URL or default to home
            } else {
                alert('Login failed: ' + response.data.error);
            }
        } catch (error) {
            console.error('Login Failed:', error.response ? error.response.data : "No response from server");
            alert('Invalid credentials'); // Provide user feedback
        }
    };

    return (
        <div className="wrapper" style={{ marginBottom: '20px' }}>
            <form onSubmit={handleSubmit}>
                <h1>Login</h1>
                <div className="input-box">
                    <input 
                        type="text" 
                        value={username} 
                        onChange={(e) => setUsername(e.target.value)} 
                        placeholder="Username" 
                        required
                    />
                    <i className="bx bxs-user"></i>
                </div>
                <div className="input-box">
                    <input 
                        type="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        placeholder="Password" 
                        required
                    />
                    <i className="bx bxs-lock-alt"></i>
                </div>
                <button type="submit" className="btn">Login</button>
                <div className="forgot-password-link">
                    <Link to="/forgot-password">Forgot Password?</Link>
                </div>
            </form>
        </div>
    );
}

export default Login;