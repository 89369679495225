import React, { useState } from 'react';
import axios from 'axios';

function FreelancerProfileForm() {
    const [freelancerData, setFreelancerData] = useState({
        name: '',
        image: '',
        location: '',
        bio: ''
    });

    const handleChange = (event) => {
        setFreelancerData({ ...freelancerData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://api.live-and-local.com/profile', freelancerData);
            console.log('Profile Created:', response.data);
            alert('Profile created successfully!');
        } catch (error) {
            console.error('Failed to create profile:', error);
            alert('Failed to create profile');
        }
    };

    return (
        <div className="wrapper">
            <form onSubmit={handleSubmit}>
                <h1>Create Freelancer Profile</h1>
                <div className="input-box">
                    <input type="text" name="name" value={freelancerData.name} onChange={handleChange} placeholder="Name" />
                </div>
                <div className="input-box">
                    <input type="text" name="image" value={freelancerData.image} onChange={handleChange} placeholder="Image URL" />
                </div>
                <div className="input-box">
                    <input type="text" name="location" value={freelancerData.location} onChange={handleChange} placeholder="Location" />
                </div>
                <div className="input-box">
                    <textarea name="bio" value={freelancerData.bio} onChange={handleChange} placeholder="Bio" />
                </div>
                <button type="submit" className="btn">Create Profile</button>
            </form>
        </div>
    );
}

export default FreelancerProfileForm;