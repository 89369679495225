import React, { useState } from 'react';
import Geosuggest from './Geosuggest'; 
import './Geosuggest.css';
const Modal = ({ date, userType, onSave, onCancel }) => {
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [additionalFields, setAdditionalFields] = useState({
        venueType: '',
        rangeOfPeople: '',
        gigAmount: '',
        songSamples: '',
        cost: '',
        genre: '',
        performerType: '',
    });

    const handleSave = () => {
        onSave({ startTime, endTime, description, location, ...additionalFields });
    };
  
    const handleChange = (e) => {
        const { name, value } = e.target;
        setAdditionalFields({ ...additionalFields, [name]: value });
    };

    const handleSelect = (suggestion) => {
        setLocation(suggestion.label);
        console.log('Selected Address:', suggestion.label);
        console.log('Location Data:', suggestion.location);
    };

    console.log('Modal UserType:', userType);

    return (
        <div className="modal">
            <h2>Enter Details for {date.toDateString()}</h2>
            <label>
                Start Time:
                <input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
            </label>
            <label>
                End Time:
                <input type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
            </label>
            <textarea
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
            />
            <label>
                Location:
                <Geosuggest onSelect={handleSelect} />
            </label>
            {userType === 'vendor' && (
                <>
                    <label>
                        Venue Type:
                        <select name="venueType" value={additionalFields.venueType} onChange={handleChange}>
                            <option value="">Select</option>
                            <option value="bar">Bar</option>
                            <option value="club">Club</option>
                            <option value="stage">Stage</option>
                        </select>
                    </label>
                    <label>
                        Range of People:
                        <input
                            type="number"
                            name="rangeOfPeople"
                            value={additionalFields.rangeOfPeople}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        Gig Amount:
                        <input
                            type="number"
                            name="gigAmount"
                            value={additionalFields.gigAmount}
                            onChange={handleChange}
                        />
                    </label>
                </>
            )}
            {userType === 'performer' && (
                <>
                    <label>
                        Song Samples:
                        <input
                            type="text"
                            name="songSamples"
                            value={additionalFields.songSamples}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        Cost:
                        <input
                            type="number"
                            name="cost"
                            value={additionalFields.cost}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        Genre:
                        <select name="genre" value={additionalFields.genre} onChange={handleChange}>
                            <option value="">Select</option>
                            <option value="rap">Rap</option>
                            <option value="r&b">R&B</option>
                            <option value="edm">EDM</option>
                        </select>
                    </label>
                    <label>
                        Performer Type:
                        <select name="performerType" value={additionalFields.performerType} onChange={handleChange}>
                            <option value="">Select</option>
                            <option value="single">Single</option>
                            <option value="dj">DJ</option>
                            <option value="rapper">Rapper</option>
                            <option value="singer">Singer</option>
                            <option value="band">Band</option>
                        </select>
                    </label>
                </>
            )}
            <button onClick={handleSave}>Save</button>
            <button onClick={onCancel}>Cancel</button>
        </div>
    );
};

export default Modal;