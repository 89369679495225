import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Geosuggest from 'react-geosuggest';
import { Box, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import AddressConfirmationModal from './AddressConfirmationModal';


function EditProfile() {
  const { profileId } = useParams(); // Get profile ID from URL
  const [profile, setProfile] = useState({
    name: '',
    bio: '',
    location: '',
    image_url: null,
    additional_images: null,
    genre: '',
    type: '',
    experienceLevel: '',
    preferred_venues: [],
    performanceType: '',
    preferred_contact_method: '',
    phoneNumber: '',
    setting: '',
    capacity: '',
    amenities: '',
    stageTypes: '',
  });

  const settingOptions = ['Bar', 'Nightclub', 'Café', 'Restaurant', 'Lounge', 'Concert Hall','Outdoor Space', 'Brewery/Tavern', 'Private Event Space', 'Other' ];

  const stageOptions = [
      'Small (Suitable for 1-2 performers)',
      'Medium (Suitable for 3-4 performers)',
      'Large (Suitable for 4+ performers)',
      'Adjustable/Movable Stage',
      'No Dedicated Stage (floor space only)',
      'Outdoor Stage Area'
  ];

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [newImages, setNewImages] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const navigate = useNavigate();
  console.log(profile.type)
  const genres = [
    'Rock',
    'Pop',
    'Jazz',
    'Blues',
    'Hip-Hop',
    'Country',
    'EDM (Electronic Dance Music)',
    'Folk',
    'Reggae',
    'Classical',
    'Indie',
    'R&B/Soul',
    'Metal',
    'World Music',
    'Other'
  ];

  const performanceTypes = [
      'Solo Act',
      'Band',
      'Duo',
      'Ensemble'
  ];

  const experienceLevels = [
      'Beginner (1-3 years)',
      'Intermediate (3-5 years)',
      'Experienced (5-10 years)',
      'Professional (10+ years)'
  ];

  const venueTypes = [
      'Small Bar (Capacity under 100)',
      'Medium Bar (Capacity 100-300)',
      'Large Bar/Club (Capacity 300+)',
      'Outdoor Venue',
      'Restaurant',
      'Café',
      'Lounge'
  ];

  useEffect(() => {
    const fetchProfile = async () => {
      const response = await axios.get(`https://api.live-and-local.com/profile/${profileId}`,{
        withCredentials: true
      });
      setProfile({ ...response.data }); 
      console.log(profile);
      console.log(profile.type);
    };

    fetchProfile();
  }, [profileId]);


  const handleFileChange = (event) => {
    if (event.target.name === 'image_url') {
      setProfile({ ...profile, image_url: event.target.files[0] });
    } else if (event.target.name === 'additional_images') {
      setNewImages([...newImages, ...event.target.files]);
    }
  };

  const handleDeleteImage = (imageUrl) => {
    console.log('Deleting image:', imageUrl);
    setImagesToDelete(prev => [...prev, imageUrl]);
    setProfile(prevProfile => ({
      ...prevProfile,
      additional_images: prevProfile.additional_images.filter(img => img !== imageUrl)
    }));
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    Object.keys(profile).forEach((key) => {
      if (key !== 'image_url' && key !== 'additional_images') {
        formData.append(key, profile[key]);
      }
    });
    if (profile.image_url && typeof profile.image_url !== 'string') {
      formData.append('image', profile.image_url);
    }
    newImages.forEach((image) => {
      formData.append('additional_images', image);
    });
    formData.append('images_to_delete', JSON.stringify(imagesToDelete));

    try {
      const response = await axios.put(
        `https://api.live-and-local.com/profile/${profileId}`,
        formData,
        {
          withCredentials: true,
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );
      console.log('Profile updated:', response.data);
      navigate('/user/profile');
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };
    const handleChange = (event) => {
      const { name, value, type, checked } = event.target;
      if (type === 'checkbox') {
        const updatedVenues = checked
          ? [...profile.preferred_venues, value]
          : profile.preferred_venues.filter((venue) => venue !== value);
        setProfile({ ...profile, preferred_venues: updatedVenues });
      } else {
        setProfile({ ...profile, [name]: value });
      }
    };

    const handleAddressConfirmation = () => {
      setIsAddressModalOpen(true);
    };
  
    const handleConfirmAddress = (confirmedAddress) => {
      setProfile({ ...profile, location: confirmedAddress });
      setIsAddressModalOpen(false);
    };

    return (
      <div className='wrapper'>
        <form onSubmit={handleSave}>
          <h1>Edit {profile.type === 'vendor' ? 'Vendor' : 'Performer'} Profile</h1>
  
          <div className="input-box">
            <input
              type="text"
              name="name"
              value={profile.name}
              onChange={handleChange}
              placeholder="Performer Name"
              required
            />
          </div>
  
          <div className="input-box">
          <Tooltip title="Upload your main profile picture" arrow>
            <InfoIcon className="info-icon" fontSize="small" />
          </Tooltip>
          {profile.image_url && (
            <div>
              <img 
                src={typeof profile.image_url === 'string' ? profile.image_url : URL.createObjectURL(profile.image_url)} 
                alt="Profile" 
                style={{width: '100px', height: '100px'}} 
              />
            </div>
          )}
          <input type="file" name="image_url" onChange={handleFileChange} />
        </div>
      
          <div className="input-box">
            <select
              name="preferredContactMethod"
              value={profile.preferredContactMethod}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Preferred Contact Method
              </option>
              <option value="email">Email</option>
              <option value="phone">Phone</option>
            </select>
          </div>
  
          {profile.preferredContactMethod === 'phone' && (
            <div className="input-box">
              <input
                type="tel"
                name="phoneNumber"
                value={profile.phoneNumber}
                onChange={handleChange}
                placeholder="Phone Number"
                required
              />
            </div>
          )}
  
          <div className="input-box">
            <select
              name="genre"
              value={profile.genre}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select Genre
              </option>
              {genres.map((genre, index) => (
                <option key={index} value={genre}>
                  {genre}
                </option>
              ))}
            </select>
          </div>
          {profile.type === 'performer' && (
            <>
              <div className="input-box">
                <Tooltip title="Select your performance type" arrow>
                  <InfoIcon className="info-icon" fontSize="small" />
                </Tooltip>
                <select
                  name="performanceType"
                  value={profile.performanceType}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>Select Performance Type</option>
                  {performanceTypes.map((type, index) => (
                    <option key={index} value={type}>{type}</option>
                  ))}
                </select>
              </div>

              <div className="input-box">
                <Tooltip title="Select your experience level" arrow>
                  <InfoIcon className="info-icon" fontSize="small" />
                </Tooltip>
                <select
                  name="experienceLevel"
                  value={profile.experienceLevel}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>Select Experience Level</option>
                  {experienceLevels.map((level, index) => (
                    <option key={index} value={level}>{level}</option>
                  ))}
                </select>
              </div>

              <div className="input-box">
                <Tooltip title="Select your genre" arrow>
                  <InfoIcon className="info-icon" fontSize="small" />
                </Tooltip>
                <select
                  name="genre"
                  value={profile.genre}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>Select Genre</option>
                  {genres.map((genre, index) => (
                    <option key={index} value={genre}>{genre}</option>
                  ))}
                </select>
              </div>

              <div className="input-box dropdown-checkbox">
                <Tooltip title="Select your preferred venue types" arrow>
                  <InfoIcon className="info-icon" fontSize="small" />
                </Tooltip>
                <div className="dropdown-header" onClick={() => setDropdownOpen(!dropdownOpen)}>
                  Preferred Venue Types
                  <span className={`arrow ${dropdownOpen ? 'open' : ''}`}></span>
                </div>

                {dropdownOpen && (
                  <div className="dropdown-content">
                    {venueTypes.map((venue, index) => (
                      <div key={index} className="checkbox-item">
                        <input
                          type="checkbox"
                          id={`venue-${index}`}
                          name="preferredVenues"
                          value={venue}
                          checked={profile.preferred_venues.includes(venue)}
                          onChange={handleChange}
                        />
                        <label htmlFor={`venue-${index}`}>{venue}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}

          {/* Vendor-specific fields */}
          {profile.type === 'vendor' && (
            <>
             {profile.additional_images && profile.additional_images.map((image, index) => (
                <div key={index}>
                  <img src={image} alt={`Additional ${index + 1}`} style={{width: '100px', height: '100px'}} />
                  <button type="button" onClick={() => handleDeleteImage(image)}>Delete</button>
                </div>
              ))}
              <div className="input-box">
                <Tooltip title="Enter your venue location" arrow>
                  <InfoIcon className="info-icon" fontSize="small" />
                </Tooltip>
                <Geosuggest
                  placeholder="Location"
                  initialValue={profile.location}
                  onSuggestSelect={(suggest) => {
                    if (suggest) {
                      setProfile({ ...profile, location: suggest.description });
                    }
                  }}
                />
                <button type="button" onClick={handleAddressConfirmation}>
                  Confirm Address
                </button>
              </div>

              <div className="input-box dropdown-checkbox">
                <Tooltip title="Select your venue settings" arrow>
                  <InfoIcon className="info-icon" fontSize="small" />
                </Tooltip>
                <div className="dropdown-header" onClick={() => setDropdownOpen(!dropdownOpen)}>
                  Venue Settings
                  <span className={`arrow ${dropdownOpen ? 'open' : ''}`}></span>
                </div>
                {dropdownOpen && (
                  <div className="dropdown-content">
                    {settingOptions.map((setting) => (
                      <div key={setting} className="checkbox-item">
                        <input
                          type="checkbox"
                          id={setting}
                          name="setting"
                          value={setting}
                          checked={profile.setting.includes(setting)}
                          onChange={handleChange}
                        />
                        <label htmlFor={setting}>{setting}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="input-box dropdown-checkbox">
                <Tooltip title="Select your stage types" arrow>
                  <InfoIcon className="info-icon" fontSize="small" />
                </Tooltip>
                <div className="dropdown-header" onClick={() => setDropdownOpen(!dropdownOpen)}>
                  Stage Types
                  <span className={`arrow ${dropdownOpen ? 'open' : ''}`}></span>
                </div>
                {dropdownOpen && (
                  <div className="dropdown-content">
                    {stageOptions.map((stageType) => (
                      <div key={stageType} className="checkbox-item">
                        <input
                          type="checkbox"
                          id={stageType}
                          name="stageTypes"
                          value={stageType}
                          checked={profile.stageTypes.includes(stageType)}
                          onChange={handleChange}
                        />
                        <label htmlFor={stageType}>{stageType}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="input-box capacity-slider">
                <Tooltip title="Set your venue capacity" arrow>
                  <InfoIcon className="info-icon" fontSize="small" />
                </Tooltip>
                <label htmlFor="capacity">Capacity: {profile.capacity}</label>
                <input
                  type="range"
                  id="capacity"
                  name="capacity"
                  min="0"
                  max="500"
                  step="50"
                  value={profile.capacity}
                  onChange={handleChange}
                />
              </div>

              <div className="input-box">
                <Tooltip title="Enter additional notes or amenities" arrow>
                  <InfoIcon className="info-icon" fontSize="small" />
                </Tooltip>
                <textarea
                  name="amenities"
                  value={profile.amenities}
                  onChange={handleChange}
                  placeholder="Additional Notes/Amenities"
                />
              </div>
            </>
          )}
  

  
          <div className="input-box">
            <textarea
              name="bio"
              value={profile.bio}
              onChange={handleChange}
              placeholder="Bio"
            />
          </div>
  
          <button type="submit" className="btn">
            Save Changes
          </button>
        </form>
      </div>
    );
  };
  
  export default EditProfile;
  