import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, TextField, Button, IconButton, 
  Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper 
} from '@mui/material';
import { Search, FilterList, MoreVert } from '@mui/icons-material';
import BookingDetailsModal from './Imp_bookingDetailModal';
import ContractPopup from './ContractPopup';

const ArtistBookingDashboard = () => {
  const [bookings, setBookings] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showContractPopup, setShowContractPopup] = useState(false);

  // Fetch available bookings when the component mounts
  useEffect(() => {
    fetch('https://api.live-and-local.com/api/available-bookings')
      .then(response => response.json())
      .then(data => {
        // Process the data to combine start-time and end-time
        const processedData = data.map(booking => ({
          ...booking,
          time: `${booking['start-time']} - ${booking['end-time']}`
        }));
        setBookings(processedData);
      })
      .catch(error => console.error('Error fetching bookings:', error));
  }, []);

  // Handler for search input
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter bookings based on search term
  const filteredBookings = bookings.filter(booking =>
    booking.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handler for when a booking is selected
  const handleBookingClick = (booking) => {
    setSelectedBooking(booking);
  };

  // Handler for bidding on a booking
  const handleBidClick = () => {
    setShowContractPopup(true);
  };

  // Handler for closing the contract popup
  const handleCloseContractPopup = () => {
    setShowContractPopup(false);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Available Bookings
      </Typography>

      {/* Search and Filter Section */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <TextField 
          placeholder="Search bookings..." 
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <Search />,
          }}
          sx={{ width: '60%' }}
        />
        <Box>
          <Button variant="outlined" sx={{ mr: 1 }}>
            Filters
          </Button>
          <IconButton>
            <FilterList />
          </IconButton>
        </Box>
      </Box>

      {/* Bookings Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ bgcolor: '#f0f0f0' }}>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>DATE</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>TIME</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>VENUE</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>STATUS</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>EVENT</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>GENRES</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredBookings.map((booking) => (
              <TableRow 
                key={booking.id} 
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => handleBookingClick(booking)}
              >
                <TableCell>{booking.date}</TableCell>
                <TableCell>{booking.time}</TableCell>
                <TableCell>{booking.location}</TableCell>
                <TableCell>{booking.status}</TableCell>
                <TableCell sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                  {booking.title}
                </TableCell>
                <TableCell>
                  {booking.details.genres.join(', ')}
                </TableCell>
                <TableCell>
                  <IconButton>
                    <MoreVert />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Booking Details Modal */}
      {selectedBooking && (
        <BookingDetailsModal 
          booking={selectedBooking} 
          onClose={() => setSelectedBooking(null)} 
          onBid={handleBidClick}
        />
      )}

      {/* Contract Popup */}
      {showContractPopup && (
        <ContractPopup 
          booking={selectedBooking} 
          onClose={handleCloseContractPopup} 
        />
      )}
    </Box>
  );
};

export default ArtistBookingDashboard;