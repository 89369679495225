import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

function AboutUsPage() {
  return (
    <Container maxWidth="lg">
      <Box my={4}>
      <Typography 
        variant="h4" 
        component="h1" 
        gutterBottom
        style={{ textAlign: 'center', color: '#EF6191', fontFamily: "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif" }}
        >
        About Live & Local
        </Typography>
        
        <Paper style={{ padding: '20px', marginTop: '20px', backgroundColor: '#f7f7f7', fontFamily: "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif"  }}>
          
          <Typography variant="h6" component="h2">
            Introduction
          </Typography>
          <Typography paragraph>
            The current system for artists looking to pursue their passion is inefficient and often discouraging. Talented musicians find themselves spending more time acting as agents and businessmen rather than focusing on their art. At Live & Local, we believe that artists should be free to create, not bogged down by the complexities of booking gigs. Our platform is designed to give artists a sense of security and the freedom to pursue their creative dreams, knowing that their booking process is in capable hands.
          </Typography>

          <Typography variant="h6" component="h2">
            Mission Statement
          </Typography>
          <Typography paragraph>
            Live & Local's mission is to revolutionize and automate the booking process for artists and venues, making it more efficient and equitable. We aim to eliminate the disparity in success caused by monetary discretion, ensuring that all artists, regardless of financial resources, have equal opportunities to perform and succeed. At the same time, we strive to streamline the process for venues, providing them with easy access to a diverse pool of talented artists. By simplifying the booking process, we enable artists to focus on their creative endeavors and venues to enhance their live music offerings.
          </Typography>

          <Typography variant="h6" component="h2">
            Vision
          </Typography>
          <Typography paragraph>
            We envision a world where artists can pursue their careers without the hindrance of acting as their own agents. Live & Local seeks to level the playing field, providing every artist with the tools they need to find the right venues that match their time, place, genre, and pay preferences. Our platform ensures that success is based on talent and passion, not on financial capabilities.
          </Typography>

          <Typography variant="h6" component="h2">
            Our Approach
          </Typography>
          <Typography paragraph>
            As passionate live music enthusiasts, our best interests align with creating an efficient gig booking platform. Live & Local utilizes an advanced algorithm to match artists and venues based on aligned visions and preferences. This ensures that artists find the perfect fit for their performances and venues discover the best talent to entertain their audiences.
          </Typography>

          {/* Add more sections as needed */}
        </Paper>
      </Box>
    </Container>
  );
}

export default AboutUsPage;