import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './BookingCardInfo.css';

function BookingCardInfo({ userId, userType }) {
  const [datesInfo, setDatesInfo] = useState([]);
  const [statusType, setStatusType] = useState('available');  // Default to showing available dates
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchUserSpecificDetails = async () => {
      try {
        const response = await axios.get(`https://api.live-and-local.com/specific-details/${userId}?status=${statusType}`);
        if (response.data.message) {
          setMessage(response.data.message);
          setDatesInfo([]);
        } else {
          setMessage('');
          setDatesInfo(response.data);
        }
      } catch (error) {
        console.error('Failed to fetch user-specific details:', error);
      }
    };

    fetchUserSpecificDetails();
  }, [userId, statusType]);

  return (
    <div className="booking-page">
      <div className="nav-bar">
        <button className={`nav-button ${statusType === 'available' ? 'active' : ''}`} onClick={() => setStatusType('available')}>Available</button>
        <button className={`nav-button ${statusType === 'booked' ? 'active' : ''}`} onClick={() => setStatusType('booked')}>Booked</button>
        <button className={`nav-button ${statusType === 'interest' ? 'active' : ''}`} onClick={() => setStatusType('interest')}>Interest Shown</button>
      </div>
      <div className="content">
      {message && <p>{message}</p>}
        {datesInfo.length > 0 ? (
          datesInfo.map((info, index) => (
            <div key={index} className="date-info">
              <p>Date: {info.date}</p>
              <p>Status: {info.status}</p>
              <p>Booking Description: {info.description}</p>

              

              {userType === 'vendor' && (
                <div>
                {info.venue_type && <p>Venue Type: {info.venue_type}</p>}
                {info.range_of_people && <p>Range of People: {info.range_of_people}</p>}
                {info.gig_amount && <p>Gig Amount: {info.gig_amount}</p>}
                </div>
              )}
              {userType === 'performer' && (
                <div>
                  {info.song_samples && <p>Song Samples: {info.song_samples}</p>}
                  {info.cost && <p>Cost: {info.cost}</p>}
                  {info.genre && <p>Genre: {info.genre}</p>}
                  {info.performer_type && <p>Performer Type: {info.performer_type}</p>}
                  {info.booking_location && <p>Booking Location: {info.booking_location}</p>}
                </div>
              )}
               {statusType === 'booked' && info.booked_by && (
                <div className="user-info">
                  <p>Booked By: {info.booked_by.username}</p>
                  <p>Email: {info.booked_by.email}</p>
                  <p>Name: {info.booked_by.name}</p>
                  <p>Location: {info.booked_by.location}</p>
                  <p>Bio: {info.booked_by.bio}</p>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>...</p>
        )}
      </div>
    </div>
  );
}

export default BookingCardInfo;