import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './booking.css';


function BookingPage() {
    const { profile_id } = useParams();
    const [availableDates, setAvailableDates] = useState([]);
    const [selectedDateId, setSelectedDateId] = useState(null);
    const [bookingDetails, setBookingDetails] = useState('');
    const [contactDetails, setContactDetails] = useState('');
    const [profileName, setProfileName] = useState('');

    useEffect(() => {
        const fetchAvailableDates = async () => {
            try {
                const response = await axios.get(`https://api.live-and-local.com/view_profile/${profile_id}`);
                setAvailableDates(response.data.availableDates);
                setProfileName(response.data.name);
            } catch (err) {
                console.error("Failed to fetch available dates:", err);
            }
        };
    
        fetchAvailableDates();
    }, [profile_id]);

    const handleBook = async () => {
        if (!selectedDateId) {
            alert("Please select a date");
            return;
        }
        try {
            const response = await axios.post('https://api.live-and-local.com/show-interest', {
                date_id: selectedDateId,
                details: bookingDetails
            }, { withCredentials: true });
            if (response.status === 200) {
                alert(`Request Successfully Sent to ${profileName}. View Messages for Confirmation.`);
            }
        } catch (error) {
            console.error('Error making booking:', error);
            alert(`Error making booking: ${error.response?.data?.error || error.message}`);
        }
    };

    const handleContact = async () => {
        try {
            const response = await axios.post('https://api.live-and-local.com/contact', {
                profileId: profile_id,
                contactDetails
            }, { withCredentials: true });
            if (response.status === 201) {
                alert('Message sent successfully');
            }
        } catch (error) {
            console.error('Error sending message:', error);
            alert(`Error sending message: ${error.response?.data?.error || error.message}`);
        }
    };

    return (
        <div className="booking-page">
            <h2>Booking Page for {profileName}</h2>
            <div className="details">
                <p>Available Dates:</p>
                <select onChange={(e) => setSelectedDateId(e.target.value)}>
                    <option value="">Select a date</option>
                    {availableDates.map(date => (
                        <option key={date.id} value={date.id}>
                            {new Date(date.date).toLocaleDateString()} - {date.start_time} to {date.end_time}
                        </option>
                    ))}
                </select>
                <input type="text" placeholder="Booking details" onChange={(e) => setBookingDetails(e.target.value)} />
                <button className="button" onClick={handleBook}>Request Booking</button>
            </div>
            
            <div className="details">
                <p>If none of these dates work for you, please provide your contact details and we will get back to you:</p>
                <input type="text" placeholder="Your contact details" onChange={(e) => setContactDetails(e.target.value)} />
                <button className="button" onClick={handleContact}>Contact</button>
            </div>
        </div>
    );
}

export default BookingPage;